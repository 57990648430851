<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Lomakkeet</h1>
      </div>
    </div>
    <div class="container mt-12">
      <h2>Inputs</h2>
      <div class="component">
        <div>
          <base-input v-model="form.firstname" name="forms.firstname" label="Etunimi" />
        </div>
        <div class="min-w-0">
          <pre class="code">import BaseInput from '@bit/vekenkaluste.veke-ui.input'
&lt;base-input name="firstname" label="Etunimi" v-model="form.firstname" /></pre>
        </div>
      </div>
      <div class="component">
        <div>
          <email-field v-model="form.email" name="forms.email" />
        </div>
        <div class="min-w-0">
          <pre class="code">import EmailField from '@bit/vekenkaluste.veke-ui.email-field'
&lt;email-field name="user-email" v-model="form.email" /></pre>
          <p class="text-12 font-bold">
            Has separate Vue2 and Vue3 components!
          </p>
        </div>
      </div>
      <div class="component">
        <div>
          <password-field v-model="form.password" name="forms.password" />
        </div>
        <div class="min-w-0">
          <pre class="code">import PasswordField from '@bit/vekenkaluste.veke-ui.password-field'
&lt;password-field name="user-password" v-model="form.password" /></pre>
          <p class="text-12 font-bold">
            Has separate Vue2 and Vue3 components!
          </p>
        </div>
      </div>
      <div class="component">
        <div>
          <search-field v-model="form.search" name="forms.search" />
        </div>
        <div class="min-w-0">
          <pre class="code">import SearchField from '@bit/vekenkaluste.veke-ui.search-field'
&lt;search-field name="user-search" v-model="form.search" /></pre>
          <p class="text-12 font-bold">
            Has separate Vue2 and Vue3 components!
          </p>
        </div>
      </div>
    </div>
    <div class="container mt-12">
      <h2>Number</h2>
      <div class="component">
        <div>
          <number-field v-model="form.number" name="forms.number" />
        </div>
        <div class="min-w-0">
          <pre class="code">import NumberField from '@bit/vekenkaluste.veke-ui.number-field'
&lt;number-field name="product-qty" v-model="form.qty" /></pre>
          <p class="text-12 font-bold">
            Has separate Vue2 and Vue3 components!
          </p>
        </div>
      </div>
      <h2>Select</h2>
      <div class="component">
        <div>
          <base-select
            v-model="form.store"
            name="forms.store"
            label="Myymälä"
          >
            <option value="VAN">
              Vantaa
            </option>
            <option value="TAM">
              Tampere
            </option>
            <option value="OUL">
              Oulu
            </option>
            <option value="ROV">
              Rovaniemi
            </option>
          </base-select>
        </div>
        <div class="min-w-0">
          <pre class="code">import BaseSelect from '@bit/vekenkaluste.veke-ui.select'
&lt;base-select name="store" label="Myymälä" placeholder="Valitse myymälä" v-model="form.store">
  &lt;option value="VAN">Vantaa&lt;/option>
  &lt;option value="TAM">Tampere&lt;/option>
&lt;/base-select></pre>
        </div>
      </div>
      <h2>Checkbox</h2>
      <h3>Boolean checkbox</h3>
      <div class="component">
        <div>
          <base-checkbox v-model="form.marketing" name="forms.marketing" label="Veke saa lähettää minulle sähköpostia" />
        </div>
        <div class="min-w-0">
          <pre class="code">import BaseCheckbox from '@bit/vekenkaluste.veke-ui.checkbox'
&lt;base-checkbox id="marketing_permission" name="marketing-permission" label="Veke saa lähettää minulle sähköpostia" /></pre>
        </div>
      </div>
      <h3>Checkbox group</h3>
      <div class="component">
        <div>
          <checkbox-group v-model="form.messaging" name="forms.messaging" :options="messagingOptions" />
        </div>
        <div class="min-w-0">
          <pre class="code">import CheckboxGroup from '@bit/vekenkaluste.veke-ui.checkbox-group'
&lt;checkbox-group v-model="form.messaging" name="forms.messaging" :options="[{ id: 'sms', label: 'Tekstiviestitse', 'value': sms }, { id: 'email', label: 'Sähköpostitse', 'value': email }]" />
messaging: []</pre>
        </div>
      </div>
      <h2>Radio</h2>
      <div class="component">
        <div>
          <base-radio
            v-model="form.theme"
            name="forms.theme"
            label="Valitse teema"
            :options="themeOptions"
            required
          >
            <template #theme-lavender>
              <p>Hyvä valinta!</p>
            </template>
          </base-radio>
        </div>
        <div class="min-w-0">
          <pre class="code">import BaseRadio from '@bit/vekenkaluste.veke-ui.radio'
&lt;base-radio id="yes_or_no" :options="[{ label: 'Kyllä', value: 'yes'}, { label: 'Ei', value: 'no' }]" /></pre>
        </div>
      </div>
      <h2>Textarea</h2>
      <div class="component">
        <div>
          <base-textarea
            v-model="form.feedback"
            name="forms.feedback"
            label="Palaute"
            placeholder="Mitä pidit kaupastamme?"
          >
          </base-textarea>
        </div>
        <div class="min-w-0">
          <pre class="code">import BaseTextarea from '@bit/vekenkaluste.veke-ui.textarea'
&lt;base-textarea name="feedback" v-model="form.feedback" label="Palaute" placeholder="Mitä pidit kaupastamme?" /></pre>
        </div>
      </div>
    </div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <span class="alpha">
          Esimerkkejä
        </span>
      </div>
    </div>
    <section class="theme-default">
      <div class="container py-container">
        <div class="theme-lavender rounded max-w-sm p-6">
          <login-form />
        </div>
      </div>
    </section>
    <section class="bg-rain">
      <div class="container py-container">
        <div class="max-w-lg">
          <contact-info-form />
        </div>
      </div>
    </section>
    <section class="bg-sunbeam">
      <div class="container py-container">
        <div class="">
          <newsletter-form />
        </div>
      </div>
    </section>
    <section class="bg-lavender">
      <div class="container py-container">
        <div class="">
          <search-form />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseInput from '@bit/vekenkaluste.veke-ui.input'
import BaseCheckbox from '@bit/vekenkaluste.veke-ui.checkbox'
import CheckboxGroup from '@bit/vekenkaluste.veke-ui.checkbox-group'
import BaseRadio from '@bit/vekenkaluste.veke-ui.radio'
import BaseTextarea from '@bit/vekenkaluste.veke-ui.textarea'
import BaseSelect from '@bit/vekenkaluste.veke-ui.select'
import EmailField from '@bit/vekenkaluste.veke-ui.vue3.email-field'
import PasswordField from '@bit/vekenkaluste.veke-ui.vue3.password-field'
import SearchField from '@bit/vekenkaluste.veke-ui.vue3.search-field'
import NumberField from '@bit/vekenkaluste.veke-ui.vue3.number-field'
import ContactInfoForm from '@/components/forms/ContactInfoForm.vue'
import LoginForm from '@/components/forms/LoginForm.vue'
import NewsletterForm from '@/components/forms/NewsletterForm.vue'
import SearchForm from '@/components/forms/SearchForm.vue'

export default {
  name: 'Forms',
  components: {
    LoginForm,
    BaseInput,
    BaseCheckbox,
    CheckboxGroup,
    BaseTextarea,
    BaseRadio,
    BaseSelect,
    EmailField,
    PasswordField,
    SearchField,
    NumberField,
    ContactInfoForm,
    NewsletterForm,
    SearchForm,
  },
  data() {
    return {
      form: {
        theme: 'theme-default',
        messaging: [],
        number: 1,
      },
      messagingOptions: [
        {
          id: 'sms',
          label: 'Tekstiviestitse',
          value: 'sms',
        },
        {
          id: 'email',
          label: 'Sähköpostitse',
          value: 'email',
        },
        {
          id: 'letter',
          label: 'Kirjeitse',
          value: 'letter',
        },
      ],
      themeOptions: [
        {
          label: 'Default',
          value: 'theme-default',
        },
        {
          label: 'Gunmetal',
          value: 'theme-gunmetal',
        },
        {
          label: 'Lavender',
          value: 'theme-lavender',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
  .component {
    @apply grid gap-8 md:grid-cols-2 pb-8 mb-8;
  }
</style>

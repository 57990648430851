<template>
  <filter-dropdown :label="filter.name">
    <template #selection>
      {{ preview }}
    </template>
    <div class="p-4">
      <input
        v-model.number="value"
        type="range"
        min="0"
        max="3000"
        class="w-full h-10"
      />
      <div class="text-center font-bold">
        0 € &ndash; {{ value }} €
      </div>
    </div>
  </filter-dropdown>
</template>

<script>
import FilterDropdown from '@/veke3000/components/filters/FilterDropdown.vue'
import {
  computed,
  defineComponent, ref,
} from 'vue'

export default defineComponent({
  name: 'RangeFilter',
  components: {
    FilterDropdown,
  },
  inheritAttrs: false,
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const value = ref(100)
    const preview = computed(() => (value.value ? `${value.value} €` : ''))
    return {
      value, preview,
    }
  },
})
</script>

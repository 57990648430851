<template>
  <div class="filter-menu">
    <div class="filter-menu-content">
      <section class="theme-gunmetal h-20 py-2 flex items-center">
        <base-button look="primary" class="theme-gunmetal w-12 h-12 px-0 fixed rounded-full top-4 right-4 z-30" @click="close">
          <x-icon />
        </base-button>
        <div class="container">
          <h2 class="mb-0">
            Rajaa
          </h2>
        </div>
      </section>
      <section class="bg-white">
        <filters />
        <div class="container sticky bottom-0 bg-white-glass-bottom">
          <base-button class="flex-none w-full my-4" @click="close">
            <sliders-icon size="1.5x" class="icon-mr" /><span>Näytä tuotteet</span>
          </base-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
} from 'vue'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import Filters from '@/veke3000/components/filters/Filters.vue'
import {
  XIcon,
  SlidersIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default defineComponent({
  name: 'FilterMenu',
  components: {
    BaseButton,
    XIcon,
    SlidersIcon,
    Filters,
  },
  setup() {
    const close = () => {
      document.body.classList.remove('filter-menu-open')
    }
    return {
      close,
    }
  },
})
</script>

<style lang="scss">
.filter-menu {
  @apply invisible w-screen h-screen fixed z-50 inset-y-0 -right-full overflow-y-auto overflow-x-hidden bg-white/80 w-screen;

  .filter-menu-content {
    @apply shadow-lg absolute inset-x-0 min-h-screen transform translate-x-8 w-screen opacity-0;
    transition: transform 600ms, opacity 200ms;
  }
  &.submenu-open {
    @apply overflow-y-hidden;
  }
}

.filter-menu-open {
  @apply overflow-hidden;
  .filter-menu {
    @apply visible right-auto;
    .filter-menu-content {
      @apply transform-none opacity-100;
    }
  }
}
</style>

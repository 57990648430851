<template>
  <div class="container">
    <heading-link to="/kampanjat" button-text="Näytä kampanjat">
      <h2>Tämän viikon tarjoukset</h2>
    </heading-link>
  </div>
  <div class="lg:container">
    <div class="grid grid-flow-col lg:grid-flow-row gap-4 py-4 overflow-x-auto px-4 md:px-8 lg:px-0 justify-start lg:grid-cols-4">
      <div v-for="campaign in campaigns" :key="campaign.id" class="campaign-banner">
        <router-link :to="campaign.slug">
          <img :src="require('@/assets/images/'+campaign.banners.square)" :alt="campaign.text" :title="campaign.text" />
        </router-link>
      </div>
      <div class="w-2 md:w-4"></div>
    </div>
  </div>
</template>

<script>

import HeadingLink from '@/veke3000/components/HeadingLink.vue'
import { campaigns } from '@/veke3000/components/home/Campaigns'

export default {
  name: 'Campaigns',
  components: {
    HeadingLink,
  },
  setup() {
    return { campaigns }
  },
}
</script>

<style lang="scss" scoped>
.campaign-banner {
  @apply bg-white overflow-hidden rounded shadow-md;
  width: clamp(120px, 55vw, 310px);
  max-width: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}
</style>

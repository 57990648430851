<template>
  <nav class="sg-nav">
    <ul>
      <li v-for="item in items" :key="item.path">
        <router-link :to="itemPath(item.path)">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'Navigation',
  props: {
    items: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    root: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    itemPath(path) {
      return this.root ? `/${this.root}/${path}` : `/${path}/`
    },
  },
}
</script>
<style lang="scss" scoped>
.sg-nav {
  @apply overflow-x-auto text-center;
  > ul {
    @apply m-0 inline-grid grid-flow-col gap-4 auto-cols-min px-5;
    > li {
      @apply inline-block m-0;
      > a {
        @apply no-underline inline-block px-6 py-3 bg-white text-text font-medium whitespace-nowrap;
        &.router-link-active {
          @apply bg-coral text-black;
        }
        &.router-link-exact-active {
          @apply bg-coral text-black;
        }
      }
    }
  }
}
</style>

const categoriesJson = require('@/assets/categories.json')

const categoryExtensions = {
  8: {
    sortOrder: 1,
    product: 'sohva.png',
    image: 'category_huonekalut.webp',
    description: 'Kalusteet tekevät kodin. Oikeanlaiset huonekalut vastaavat tarpeisiisi, istuvat tyyliisi sekä jättävät tilaa elämälle. Vekeltä löydät kauniit ja laadukkaat huonekalut kaikkiin elämänvaiheisiin sekä kaikkiin koteihin. Tilaa Vekeltä huonekalut helposti kotisohvaltasi käsin nopealla ja edullisella toimituksella! Huonekalujen tilaaminen meiltä on turvallista ja joustavaa. Tarjoamme joustavat maksuehdot laskulla tai erämaksulla.',
  },
  9: {
    sortOrder: 4,
    product: 'kasvi.webp',
    image: 'category_sisustus.webp',
    description: 'Sisustustuotteilla muutat huoneen kuin huoneen ilmettä pienellä vaivalla ja rahalla. Veken verkkokauppa tarjoaa lukuisia mielenkiintoisia sisustustavaroita, joilla muutat kotisi ilmettä kätevästi vaikkapa vuodenaikojan mukaan. Sisustustuotteet luovat yhtenäisyyttä tai halutessasi toimivat pieninä katseen vangitsijoina, piristävinä detaljeina. Lisäksi sisustaminen on todella hauskaa! Tutustu laajaan ja moni-ilmeiseeen sisustustavaravalikoimaamme ja tilaa omasi jo tänään - edullisen kotiinkuljetuksen saat halutessasi aivan kotiovellesi saakka. Jos kotisi kaipaa turhan tavaran karsimista, kannattaa käydä lukemassa myös vinkkimme kodin järjestämiseen.',
    popular: 1,
  },
  10: {
    sortOrder: 2,
    product: 'matto.jpeg',
    image: 'category_matot.jpeg',
    description: 'Muuta kodin ilmettä helposti matolla. Panostit sitten materiaaleihin tai helppohoitoisuuteen, Vekeltä löydät varmasti tyyliisi sopivat matot. Mietitkö mikä matto kotiisi sopisi parhaiten? Lue vinkkimme maton valintaan.',
    popular: 1,
  },
  3: {
    sortOrder: 3,
    product: 'riipputuoli.webp',
    image: 'category_puutarhakalusteet.jpeg',
    description: 'Tyylikkäät ja kestävät puutarhakalusteet Vekeltä, joiden avulla teet juuri sinun näköisesi puutarhan tai terassin. Tarjoamme laajan valikoiman kauniita ja kestäviä terassikalusteita ja muita ulkokalusteita lukuisissa eri väreissä, tyyleissä ja materiaaleissa. Meiltä jokaisen tyyliin sopivat ulkosohvat, aurinkovarjot, riipputuolit ja pihakeinut. Löydät myös muut terassikalusteet, kuten paviljongit ja hienot ruokailuryhmät. Valikoimamme sopii niin suurelle omakotitalolle kuin pienelle asunnolle. Löydä suosikkisi ja tilaa kätevästi kotiovelle asti!',
    popular: 1,
  },
  2946: {
    sortOrder: 5,
    product: 'tyynyt.jpeg',
    image: 'category_tekstiilit.webp',
    description: 'Tekstiileillä luot kotiisi persoonallisen tunnelman. Tekstiilien käyttö pehmentää ääniä ja luo viihtyisyyttä kaikissa kodin tiloissa. Sisustuksen muuttaminen tekstiilejä vaihtamalla on helppoa, mukavaa ja myös edullista; vaihtele koristetyynyjä, torkkupeittoja, verhoja, ja vuodevaatteiden kuoseja vuodenaikojen mukaan ja kotisi ilme piristyy välittömästi.',
    popular: 1,
  },
  12: {
    sortOrder: 6,
    product: 'valaisin.webp',
    image: 'category_valaisimet.webp',
    description: 'Luo tunnelmaa valaistuksella. Vekeltä löydät niin käytännölliset kuin kauniitkin valaisimet aina trendikkäistä uutuuksista rakastettuihin design-klassikkoihin. Tutustu rauhassa laajaan valikoimaamme kotisohvaltasi käsin sekä tilaa suosikkisi helposti ja nopeasti verkkokaupastamme! Mikäli kodin oikeanlainen valaiseminen mietityttää, lue hyödylliset vinkkimme valaisimen valintaan huoneittain.',
    popular: 1,
  },
  32: {
    image: 'category_lastenkalusteet.webp',
  },
  26: {
    image: 'category_sohvat.webp',
    product: 'sohva.png',
    description: 'Sohva on olohuoneen keskipiste. Vekeltä löydät jokaiseen kotiin, tyyliin ja elämäntilanteeseen sopivan sohvan! Kulmasohva on hyvä valinta isoon olohuoneeseen ja vuodesohva tarjoaa majoitusmahdollisuuden. Divaanisohva on varma valinta rentouttavaan oleskeluun ja mekanismisohva takaa täydellisen elokuvaelämyksen. Tutustu valikoimaamme ja hanki juuri sinulle paras sohva! Meiltä tilaat tuotteesi turvallisesti ja helposti.',
    popular: 1,
  },
  27: {
    image: 'category_tuolit.webp',
    product: 'tuoli.webp',
    popular: 1,
  },
  31: {
    popular: 1,
    product: 'poyta.webp',
  },
  29: {
    popular: 1,
    product: 'sanky.webp',
  },
}

const categories = categoriesJson.map((cat) => ({ ...cat, ...categoryExtensions[cat.id], product: categoryExtensions[cat.id]?.product || 'sohva.png' })).sort((a, b) => a.sortOrder - b.sortOrder)

const createCategoryTree = (cats = categories, parentId = null) => cats
  .filter((item) => item.parentId === parentId)
  .map((item) => ({ ...item, children: createCategoryTree(cats, item.id) }))
  .sort((a, b) => a.sortOrder - b.sortOrder)

const categoryTree = createCategoryTree(categories)

const popularCategories = categories.filter((cat) => cat.popular)

const getCategoryTreeById = (categoryId) => {
  const category = categories.find((cat) => cat.id === categoryId)
  if (!category) {
    return undefined
  }
  category.children = createCategoryTree(categories, category.id)
  return category
}

const getCategoryBySlug = (slug) => categories.find((c) => c.slug === slug)

const getBreadcrumbsByCategoryId = (categoryId, cats = []) => {
  if (!categoryId) return cats.map((cat) => ({ slug: `/veke3000/c/${cat.slug}`, name: cat.value, id: cat.id }))
  const category = categories.find((cat) => cat.id === categoryId)
  if (!category) {
    return undefined
  }
  return getBreadcrumbsByCategoryId(category.parentId, [category, ...cats])
}

export {
  categories,
  createCategoryTree,
  categoryTree,
  popularCategories,
  getCategoryBySlug,
  getCategoryTreeById,
  getBreadcrumbsByCategoryId,
}

<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Värit</h1>
      </div>
    </div>
    <section class="container mt-12">
      <p class="lead">
        Kopioi värikoodi klikkaamalla!
      </p>
      <h2>Päävärit</h2>
      <div class="grid gap-1 grid-cols-2 md:grid-cols-6">
        <swatch bg="bg-terracotta" class="text-white h-64" />
        <swatch bg="bg-forest" class="text-white h-64" />
        <swatch bg="bg-moss" class="text-white h-64" />
        <swatch bg="bg-lavender" class="text-white h-64" />
        <swatch bg="bg-buttercup" class="text-white h-64" />
        <div>
          <swatch bg="bg-rose" class="text-white h-48" />
          <swatch bg="bg-rose-500" class="text-white h-16" />
        </div>
      </div>
      <h2>Lisävärit</h2>
      <div class="grid gap-1 grid-cols-2 md:grid-cols-6">
        <swatch bg="bg-pine" class="text-black h-32" />
        <swatch bg="bg-flax" class="text-black h-32" />
        <swatch bg="bg-lichen" class="text-black h-32" />
        <swatch bg="bg-rain" class="text-black h-32" />
        <swatch bg="bg-sunbeam" class="text-black h-32" />
        <div>
          <swatch bg="bg-coral" class="text-black" />
          <swatch bg="bg-coral-300" class="text-black" />
        </div>
      </div>
      <h2>Muut värit</h2>
      <div class="grid gap-y-8">
        <div class="grid gap-1 grid-cols-2 md:grid-cols-9">
          <swatch bg="bg-black" class="text-white" />
          <swatch bg="bg-white" class="text-black" />
        </div>
        <div class="grid gap-1 grid-cols-2 md:grid-cols-12">
          <swatch bg="bg-grey-50" class="text-black" />
          <swatch bg="bg-grey-100" class="text-black" />
          <swatch bg="bg-grey-200" class="text-black" />
          <swatch bg="bg-grey-300" class="text-black" />
          <swatch bg="bg-grey" class="text-black" />
          <swatch bg="bg-grey-500" class="text-white" />
          <swatch bg="bg-grey-600" class="text-white" />
          <swatch bg="bg-grey-700" class="text-white" />
          <swatch bg="bg-grey-800" class="text-white" />
          <swatch bg="bg-grey-900" class="text-white" />
          <swatch bg="bg-grey-950" class="text-white" />
        </div>
        <div class="grid gap-1 grid-cols-2 md:grid-cols-10">
          <swatch bg="bg-cotton-100" class="text-black" />
          <swatch bg="bg-cotton-200" class="text-black" />
          <swatch bg="bg-cotton-300" class="text-black" />
          <swatch bg="bg-cotton" class="text-black" />
          <swatch bg="bg-cotton-500" class="text-black" />
          <swatch bg="bg-cotton-600" class="text-black" />
          <swatch bg="bg-cotton-700" class="text-white" />
          <swatch bg="bg-cotton-800" class="text-white" />
          <swatch bg="bg-cotton-900" class="text-white" />
          <swatch bg="bg-cotton-950" class="text-white" />
        </div>
      </div>
    </section>

    <hr class="my-16">

    <section class="container mt-12">
      <h2>Vanhat värit</h2>
      <div
        class="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-8"
      >
        <!-- Red -->
        <div>
          <swatch bg="bg-ruby" class="text-white" />
          <swatch bg="bg-raspberry" class="text-white" />
          <swatch bg="bg-amaranth" class="text-white" main />
          <swatch bg="bg-radical" class="text-white" />
          <swatch bg="bg-silk" />
          <swatch bg="bg-lavender" />
        </div>

        <!-- Turquoise -->
        <div>
          <div class="h-16" />
          <swatch bg="bg-rivulet" class="text-white" />
          <swatch bg="bg-turquoise" class="text-white" main />
          <swatch bg="bg-downy" class="text-white" />
          <swatch bg="bg-snow" />
        </div>

        <!-- Green -->
        <div>
          <div class="h-16" />
          <swatch bg="bg-citron" />
          <swatch bg="bg-android" main />
          <swatch bg="bg-june" />
          <swatch bg="bg-lime" />
          <swatch bg="bg-spring" />
        </div>

        <!-- Jade -->
        <div>
          <div class="h-32" />
          <swatch bg="bg-jade" main />
        </div>

        <!-- Yellow -->
        <div>
          <div class="h-16" />
          <div class="h-16" />
          <swatch bg="bg-sunrise" main />
        </div>

        <!-- Orange -->
        <div>
          <div class="h-16" />
          <swatch bg="bg-galliano" />
          <swatch bg="bg-gold" main />
          <swatch bg="bg-peach" />
          <swatch bg="bg-papaya" />
        </div>

        <!-- Grey -->
        <div>
          <div class="h-16" />
          <swatch bg="bg-silver" />
          <swatch bg="bg-gainsboro" main />
          <swatch bg="bg-platinum" />
          <swatch bg="bg-whitesmoke" />
          <swatch bg="bg-lotion" />
        </div>

        <!-- Darks -->
        <div class="text-white">
          <div class="h-16" />
          <swatch bg="bg-space" />
          <swatch bg="bg-gunmetal" main />
          <swatch bg="bg-charcoal" />
        </div>

        <!-- Black -->
        <div>
          <div class="h-16" />
          <swatch bg="bg-black" class="text-white" />
          <swatch bg="bg-white" class="border" main />
        </div>
      </div>
    </section>
    <section class="container mt-12">
      <h2>Semanttiset värit</h2>
      <div class="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-8">
        <swatch bg="bg-error" class="text-white" />
        <swatch bg="bg-info" class="text-white" />
        <swatch bg="bg-success" class="text-black" />
        <swatch bg="bg-warning" class="text-black" />
        <swatch bg="bg-text" class="text-white" />
        <swatch bg="bg-current" text-class="text-white" />
        <swatch bg="bg-overlay" text-class="text-white" />
      </div>
    </section>
    <section class="container mt-12">
      <h2>Kampanjavärit</h2>
      <div class="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-8">
        <swatch bg="bg-amaranth" class="text-white" />
        <swatch bg="bg-silk" />
        <swatch bg="bg-lavender" />
        <swatch bg="bg-downy" class="text-white" />
        <swatch bg="bg-lime" class="text-black" />
        <swatch bg="bg-sunrise" />
        <swatch bg="bg-peach" />
        <swatch bg="bg-gunmetal" class="text-white" />
      </div>
    </section>
    <section class="container mt-12">
      <h2>Outlet</h2>
      <div class="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-8">
        <swatch bg="bg-gold" />
      </div>
    </section>
  </div>
</template>

<script>
import Swatch from '@/components/colors/Swatch.vue'

export default {
  name: 'Colors',
  components: { Swatch },
}
</script>

<template>
  <div class="flex overflow-x-auto gap-4 py-4 lg:p-0 px-container">
    <div v-for="filter in filters" :key="filter.name">
      <div role="heading" class="uppercase text-10 tracking-wide mb-2">
        {{ filter.name }}
      </div>
      <div class="flex gap-2">
        <button v-for="selection in filter.selections" :key="selection" class="rounded px-4 py-2 text-12 theme-gunmetal hover:bg-amaranth focus:outline-none whitespace-nowrap">
          {{ selection }} <x-icon size="1x" class="icon-ml" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { XIcon } from '@bit/vekenkaluste.veke-ui.icons'

// const filters = require('@/assets/filters.json')

export default defineComponent({
  name: 'Filters',
  components: {
    XIcon,
  },
  setup() {
    const filters = [
      {
        name: 'Valmiusaste',
        selections: ['Koottava itse', 'Vaatii pientä asennusta'],
      },
      {
        name: 'Brändi',
        selections: ['4Living', 'Rowico'],
      },
      {
        name: 'Huone',
        selections: ['Olohuone'],
      },
      {
        name: 'Hinta',
        selections: ['120 € – 360 €'],
      },
    ]
    return { filters }
  },
})
</script>

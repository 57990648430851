<template>
  <header class="bg-white-glass-top sticky top-0 z-50 lg:relative border-b lg:border-none">
    <div class="lg:theme-gunmetal">
      <div class="container h-16 lg:h-14 flex justify-end md:justify-between items-center">
        <div class="hidden xl:block">
          <router-link to="/asiakaspalvelu" class="link-unstyled text-current">
            Asiakaspalvelu
          </router-link>
        </div>
        <div class="hidden md:block flex-1 ml-24 lg:ml-36 xl:mx-0 mr-8">
          <search-field
            wrapper-class="w-full max-w-screen-xs lg:mx-auto hidden md:block text-14"
            name="product-search"
            hide-label
            placeholder="Etsi yli 10 000 tuotteen valikoimasta..."
          />
        </div>
        <div class="flex flex-shrink-0 gap-2 sm:gap-4 text-14">
          <base-button to="/haku" look="tertiary" class="md:hidden text-current p-0 w-12">
            <search-icon />
          </base-button>
          <base-button to="/asiakastili" look="tertiary" class="hidden sm:inline-grid text-current p-0 w-12">
            <user-icon />
          </base-button>
          <base-button to="/toivelista" look="tertiary" class="hidden xs:inline-grid text-current p-0 w-12">
            <heart-icon />
          </base-button>
          <base-button to="/ostoskori" look="tertiary" class="text-current p-0 w-12">
            <cart-icon />
          </base-button>
          <base-button look="tertiary" class="lg:hidden text-14 text-current p-0 w-12 bg-snow ml-3" @click="toggleMenu">
            <menu-icon />
          </base-button>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container lg:py-5">
        <div class="flex gap-12">
          <div class="absolute top-0 py-3 lg:py-0 xl:static">
            <router-link to="/veke3000">
              <img
                src="@/veke-ui/assets/logo/old-veke-logo.svg"
                class="inline-block h-10 lg:h-14 xl:h-16"
                alt="Veke Logo"
                style="aspect-ratio: 125 / 64"
              />
            </router-link>
          </div>
          <div class="hidden lg:block mx-auto xl:mx-0">
            <mega-menu class="mb-2" />
            <div class="header-links bg-snow mb-1 rounded px-3 text-12 flex gap-4 justify-center">
              <router-link to="/veke3000/blogi">
                Blogi
              </router-link>
              <router-link to="/veke3000/inspiraatiota">
                Inspiraatiota
              </router-link>
              <router-link to="/veke3000/uutuudet">
                Uutuudet
              </router-link>
              <router-link to="/veke3000/brandit">
                Brändit
              </router-link>
              <router-link to="/veke3000/tuotesarjat">
                Tuotesarjat
              </router-link>
              <router-link to="/veke3000/lahjakortti">
                Lahjakortti
              </router-link>
              <router-link to="/veke3000/outlet">
                Outlet
              </router-link>
              <router-link to="/veke3000/kampanjat">
                Kampanjat
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import SearchField from '@bit/vekenkaluste.veke-ui.vue3.search-field'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import MegaMenu from '@/veke3000/components/mega-menu/MegaMenu.vue'
import {
  CartIcon, UserIcon, HeartIcon, MenuIcon, SearchIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'SiteHeader',
  components: {
    MegaMenu,
    CartIcon,
    UserIcon,
    HeartIcon,
    MenuIcon,
    SearchIcon,
    SearchField,
    BaseButton,
  },
  data() {
    return {
      buttonState: 'default',
    }
  },
  methods: {
    toggleMenu() {
      document.body.classList.add('menu-open')
    },
  },
}
</script>

<style lang="scss" scoped>
.header-links > a {
  @apply block uppercase p-2 text-charcoal tracking-wider hover:text-turquoise;
}
</style>

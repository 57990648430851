module.exports = {
  black: '#000000',
  white: '#FFFFFF',
  terracotta: '#692314',
  forest: '#0f3c1e',
  moss: '#91965f',
  lavender: '#c3aff0',
  buttercup: '#ffb400',
  rose: {
    DEFAULT: '#fa7887',
    500: '#CD7784'
  },
  pine: '#c8784b',
  flax: '#dcbea5',
  lichen: '#b4c3af',
  rain: '#a5c3d7',
  sunbeam: '#ffdc82',
  coral: {
    300: '#FFD6CC',
    DEFAULT: '#ffc3b4'
  },
  cotton: {
    100: '#FBF9F7',
    200: '#F6F4EF',
    300: '#F1EEE7',
    DEFAULT: '#EDE9E0',
    500: '#E9E4D8',
    600: '#E0DBCE',
    700: '#D7D2C5',
    800: '#CFC9BB',
    900: '#C6C0B2',
    950: '#BDB7A8'
  },
  grey: {
    50: '#f2f2f2',
    100: '#e6e6e6',
    200: '#cccccc',
    300: '#b3b3b3',
    DEFAULT: '#999999',
    500: '#828282',
    600: '#6b6b6b',
    700: '#4d4d4d',
    800: '#333333',
    900: '#262626',
    950: '#1c1c1c'
  },
  red: '#AD3E4B',
  green: '#2DA329'
}

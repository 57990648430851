<template>
  <div class="relative color-swatch overflow-hidden h-16 cursor-pointer" :class="{ 'h-32 my-1': main }">
    <div
      class="absolute inset-0 p-4 flex flex-col"
      :class="bg"
      @click="copy(color)"
    >
      <div class="font-bold text-12" :class="textClass">
        {{ name }}
      </div>
      <div class="" style="font-size:10px" :class="textClass">
        {{ color }}
      </div>
    </div>
  </div>
</template>

<script>
import { theme } from '@/veke-ui/tailwind/tailwind.config'

export default {
  name: 'Swatch',
  props: {
    bg: {
      type: String,
      required: true,
      default: 'bg-transparent',
    },
    main: {
      type: Boolean,
      required: false,
      default: false,
    },
    textClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      allColors: theme.colors,
    }
  },
  computed: {
    name() {
      return this.bg.slice(3)
    },
    colors() {
      return this.explodeColors(this.allColors)
    },
    color() {
      return this.colors[this.name]
    },
  },
  methods: {
    async copy(s) {
      await navigator.clipboard.writeText(s)
    },
    explodeColors(colors) {
      const clrs = {}
      Object.entries(colors).forEach((i) => {
        const key = i[0]
        const val = i[1]
        if (typeof val === 'object' && val !== null) {
          clrs[key] = val.DEFAULT
          Object.entries(val).forEach((n) => {
            if (n[0] !== 'DEFAULT') {
              // eslint-disable-next-line prefer-destructuring
              clrs[`${key}-${n[0]}`] = n[1]
            }
          })
        } else {
          clrs[key] = val
        }
      })
      return clrs
    },
  },
}
</script>
<style lang="scss">
.color-swatch {
  background-color: white;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQoU2M8dOjQfwY0YGdnx4guxjgUFKI7GsTH5m4M3w1ChQCaVSRkSiYfsgAAAABJRU5ErkJggg==);
  &:active {
    @apply animate-shake;
  }
  > div {
    &:after {
      content: 'Kopioitu!';
      background: inherit;
      @apply font-bold text-16 absolute opacity-0 w-full p-4 h-full inset-0;
      transition: all 0s 1s;
    }
    &:active:after {
      opacity: 1;
      transition: none;
    }
  }
}
</style>

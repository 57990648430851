<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Ikonit</h1>
      </div>
    </div>
    <section class="container my-12">
      <pre class="code">import { HashIcon, HeartIcon, TruckIcon } from '@bit/vekenkaluste.veke-ui.icons'

&lt;hash-icon /> &lt;heart-icon /> &lt;truck-icon /></pre>
      <div class="mt-12 grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6">
        <div v-for="icon in icons" :key="icon" class="text-center p-4 border rounded">
          <component :is="icon" class="inline-block" /><br>
          <code class="mt-2">
            {{ icon.name }}
          </code>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as Icons from '@bit/vekenkaluste.veke-ui.icons'
import { shallowRef } from 'vue'

export default {
  name: 'Icons',
  components: {
    ...Icons,
  },
  setup() {
    const icons = shallowRef(Icons)
    return { icons }
  },
}
</script>
<style>
</style>

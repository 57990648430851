<template>
  <h2>Kirjaudu sisään</h2>
  <div class="grid gap-4">
    <email-field
      v-model="form.email"
      :validator="v$.email"
      name="user-email"
      autocomplete="email"
    />
    <password-field
      v-model="form.password"
      :validator="v$.password"
      name="user-password"
      autocomplete="password"
    />
    <base-checkbox v-model="form.rememberme" name="user-rememberme" label="Pidä minut sisäänkirjautuneena" />
    <base-button class="w-full" @click="login">
      Kirjaudu sisään
    </base-button>
    <div class="text-right">
      <router-link to="/">
        Unohditko salasanasi?
      </router-link>
    </div>
  </div>
</template>

<script>
import EmailField from '@bit/vekenkaluste.veke-ui.vue3.email-field'
import PasswordField from '@bit/vekenkaluste.veke-ui.vue3.password-field'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import BaseCheckbox from '@bit/vekenkaluste.veke-ui.checkbox'
import useVuelidate from '@vuelidate/core'
import { reactive } from 'vue'
import {
  helpers, required, email,
} from '@vuelidate/validators'

export default {
  name: 'LoginForm',
  components: {
    EmailField,
    PasswordField,
    BaseButton,
    BaseCheckbox,
  },
  setup() {
    const form = reactive({ })
    const validations = {
      required: helpers.withMessage('Vaadittu kenttä', required),
      email: helpers.withMessage('Tarkista sähköpostiosoitteen muoto', email),
    }
    const rules = {
      email: {
        email: validations.email,
        required: validations.required,
      },
      password: {
        required: validations.required,
      },
    }
    const v$ = useVuelidate(rules, form)
    const login = async () => {
      await v$.value.$validate()
    }
    return {
      login, v$, form,
    }
  },
}
</script>

<template>
  <div
    class="heading-link mb-6"
  >
    <slot />
    <base-button
      look="secondary"
      v-bind="$attrs"
      class="hidden lg:inline-grid text-12"
    >
      {{ buttonText }}
    </base-button>
    <arrow-right-icon
      size="2x"
      class="lg:hidden"
    />
    <component
      :is="element"
      v-bind="$attrs"
      class="lg:hidden link-unstyled absolute inset-0 focus:ring-1"
    ></component>
  </div>
</template>

<script>
import { ArrowRightIcon } from '@bit/vekenkaluste.veke-ui.icons'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeadingLink',
  components: { BaseButton, ArrowRightIcon },
  inheritAttrs: false,
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Näytä lisää',
    },
  },
  setup(props, context) {
    const element = context.attrs.href ? 'a' : context.attrs.to ? 'router-link' : 'div'
    return {
      element,
    }
  },
})
</script>
<style lang="scss" scoped>
.heading-link {
  @apply flex gap-4 justify-between items-center relative;
  ::v-slotted(*) {
    @apply mb-0;
  }
}
</style>

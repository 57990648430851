<template>
  <a href="#" class="image-link link-unstyled">
    <figure>
      <img :src="image" :alt="caption" />
      <figcaption v-if="caption">
        {{ caption }}
      </figcaption>
    </figure>
  </a>
</template>

<script>
export default {
  name: 'ImageLink',
  props: {
    image: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.image-link {
  @apply block w-full rounded bg-center bg-cover overflow-hidden no-underline text-white transition duration-200;
  > figure {
    @apply relative;
    > img {
      @apply w-full h-full object-cover;
      aspect-ratio: 4 / 3;
    }
    > figcaption {
      @apply absolute inset-x-0 bottom-0 w-full text-center text-white backdrop-blur-xs backdrop-filter m-0 p-1 font-bold text-14 leading-none bg-gradient-to-t from-black/40 to-transparent;
    }
  }
}
</style>

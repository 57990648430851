<template>
  <div>
    <div class="container">
      <div ref="desktopFiltersContainer" class="flex gap-4">
        <div class="flex gap-4 max-w-full" :class="{'show-all': showingAllFilters}">
          <filters />
        </div>
        <div v-if="!showingAllFilters && overflowingFilters.length" class="hidden lg:block sticky right-0 pr-4 bg-gradient-to-r from-transparent to-snow">
          <button
            title="Kaikki filtterit"
            arial-label="Kaikki filtterit"
            class="flex-none w-3em h-3em border rounded-full bg-white focus:outline-none"
            @click="showAllFilters"
          >
            <plus-icon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent, nextTick, onMounted, ref,
} from 'vue'
import Filters from '@/veke3000/components/filters/Filters.vue'
import { PlusIcon } from '@bit/vekenkaluste.veke-ui.icons'

export default defineComponent({
  name: 'DesktopFilters',
  components: {
    Filters,
    PlusIcon,
  },
  setup() {
    const desktopFiltersContainer = ref(null)
    const allFilters = computed(() => (desktopFiltersContainer.value ? Array.from(desktopFiltersContainer.value.firstChild.children) : []))
    const overflowingFilters = computed(() => allFilters.value.filter((c) => c.getBoundingClientRect().x + c.getBoundingClientRect().width + 80
      >= desktopFiltersContainer.value.getBoundingClientRect().x + desktopFiltersContainer.value.getBoundingClientRect().width))
    const getFiltersAtTheEdge = () => ((desktopFiltersContainer.value) ? allFilters.value.filter((c) => c.getBoundingClientRect().x + 384
      >= desktopFiltersContainer.value.getBoundingClientRect().x + desktopFiltersContainer.value.getBoundingClientRect().width) : [])
    const checkFiltersAtTheEdge = () => {
      allFilters.value.forEach((el) => { el.classList.remove('filter-at-edge') })
      getFiltersAtTheEdge().forEach((el) => { el.classList.add('filter-at-edge') })
    }
    const showingAllFilters = ref(false)
    const showAllFilters = async () => {
      showingAllFilters.value = true
      await nextTick()
      checkFiltersAtTheEdge()
    }
    onMounted(async () => {
      await nextTick()
      overflowingFilters.value.forEach((el) => { el.classList.add('hidden-filter') })
      checkFiltersAtTheEdge()
    })
    return {
      desktopFiltersContainer, showingAllFilters, showAllFilters, overflowingFilters,
    }
  },
})
</script>

<style lang="scss">
.filter-at-edge .filter-dropdown-content {
  @apply right-0;
}
.show-all {
  @apply flex-wrap overflow-clip;
  .hidden-filter {
    @apply block;
  }
}
.hidden-filter {
  @apply hidden;
}
</style>

<template>
  <filter-dropdown :label="filter.name">
    <template #selection>
      <div class="grid grid-flow-col gap-1">
        <template
          v-for="color in selectedColors"
          :key="color.id"
        >
          <span v-if="selectedColors.length === 1" class="mr-1">
            {{ color.label }}
          </span>
          <div
            class="w-4 h-4 rounded-full shadow-md"
            :class="swatchBorder(color)"
            :style="swatchCSS(color)"
          />
        </template>
      </div>
    </template>
    <checkbox-group
      :id="filter.id+'_'+uid"
      v-model="selectedColorValues"
      name="color"
      :options="filter.options"
      class="gap-px"
    >
      <template #checkmark="slotProps">
        <div class="checkbox border-0" :class="swatchBorder(slotProps.option)" :style="swatchCSS(slotProps.option)">
          <check-icon
            size="14"
            stroke-width="4"
            stroke="currentColor"
            class="color-checkmark"
          />
        </div>
      </template>
    </checkbox-group>
  </filter-dropdown>
</template>

<script>
import FilterDropdown from '@/veke3000/components/filters/FilterDropdown.vue'
import CheckboxGroup from '@bit/vekenkaluste.veke-ui.checkbox-group'
import {
  defineComponent, computed, ref, getCurrentInstance,
} from 'vue'
import {
  CheckIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default defineComponent({
  name: 'ColorFilter',
  components: {
    CheckIcon,
    FilterDropdown,
    CheckboxGroup,
  },
  inheritAttrs: false,
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { uid } = getCurrentInstance() // TODO: Do not use uid
    const selectedColorValues = ref([])
    const selectedColors = computed(() => selectedColorValues.value.map((c) => props.filter.options.find((clr) => clr.value === c)))
    const swatchCSS = (swatch) => `text-shadow: 0 0 3px black; color: ${swatch.color}; background: ${swatch.gradient || swatch.color}`
    const swatchBorder = (swatch) => (['white', 'blacknwhite'].includes(swatch.id) ? 'border border-gainsboro' : '')
    return {
      selectedColorValues, selectedColors, swatchCSS, swatchBorder, uid,
    }
  },
})
</script>
<style lang="scss">
.color-checkmark {
  filter: invert(1) saturate(0) brightness(2) contrast(100);
}
</style>

const plugin = require('tailwindcss/plugin')
const oldColors = require('./colorPaletteOld')
const colors = require('./colorPalette')

module.exports = {
  important: '#app',
  content: [
    './public/**/*.html',
    './src/**/*.{html,vue,js,ts,jsx,tsx}',
    './node_modules/@bit/**/*.{html,vue,js,ts,jsx,tsx}'
  ],
  theme: {
    screens: {
      xs: '360px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1440px'
    },
    colors: {
      ...oldColors,
      ...colors,
      text: colors.black,
      warning: colors.buttercup,
      info: colors.rain,
      error: colors.red,
      success: colors.green,
      overlay: 'rgba(0,0,0,0.6)',
      muted: 'rgba(0,0,0,0.6)',
      transparent: 'transparent',
      current: 'currentColor'
    },
    borderColor: theme => ({
      ...theme('colors'),
      DEFAULT: theme('colors.cotton.500', 'currentColor')
    }),
    fontSize: {
      0: ['0', { lineHeight: '0' }],
      8: ['0.5rem', { lineHeight: '1.6' }],
      9: ['0.5625rem', { lineHeight: '1.6' }],
      10: ['0.625rem', { lineHeight: '1.6' }],
      11: ['0.6875rem', { lineHeight: '1.6' }],
      12: ['0.75rem', { lineHeight: '1.6' }],
      13: ['0.8125rem', { lineHeight: '1.6' }],
      14: ['0.875rem', { lineHeight: '1.6' }],
      16: ['1rem', { lineHeight: '1.6' }],
      18: ['1.125rem', { lineHeight: '1.5' }],
      20: ['1.25rem', { lineHeight: '1.5' }],
      24: ['1.5rem', { lineHeight: '1.5' }],
      28: ['1.75rem', { lineHeight: '1.5' }],
      30: ['1.875rem', { lineHeight: '1.5' }],
      32: ['2rem', { lineHeight: '1.5' }],
      36: ['2.25rem', { lineHeight: '1' }],
      48: ['3rem', { lineHeight: '1' }],
      60: ['3.75rem', { lineHeight: '1' }]
    },
    fontFamily: {
      display: [
        'Poppins',
        'Century Gothic',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      body: [
        'Poppins',
        'Century Gothic',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      handwritten: [
        'Poppins',
        '"Brush Script MT"',
        'lato',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace'
      ]
    },
    minHeight: theme => ({
      ...theme('spacing'),
      0: '0px',
      full: '100%',
      screen: '100vh'
    }),
    boxShadow: {
      sm: '0 1px 2px 0 rgba(105, 35, 20, 0.1)', // terracotta
      DEFAULT: '0 1px 3px 0 rgba(105, 35, 20, 0.1), 0 1px 2px 0 rgba(105, 35, 20, 0.06)', // terracotta
      md: '0 4px 6px -1px rgba(105, 35, 20, 0.1), 0 2px 4px -1px rgba(105, 35, 20, 0.06)', // terracotta
      lg: '0 10px 15px -3px rgba(105, 35, 20, 0.1), 0 4px 6px -2px rgba(105, 35, 20, 0.05)', // terracotta
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      none: 'none',
      lavender: '0 8px 16px -8px rgba(231,52,84,0.3)',
      snow: '0 0 8px 0 rgb(165 185 190 / 30%), 0 2px 2px 0 rgb(165 185 190 / 40%)',
      papaya: '0 0 8px 0 rgb(201 185 156 / 30%), 0 2px 2px 0 rgb(201 185 156 / 40%)'
    },
    animation: {
      shake: 'shake 250ms ease 1',
      bounce: 'bounce 1s infinite',
      rock: 'rock 2s ease-in-out infinite alternate',
      fadepulse: 'fadepulse 1s ease-in-out infinite alternate'
    },
    keyframes: {
      fadepulse: {
        '0%': { filter: 'opacity(0.8)' },
        '100%': { filter: 'opacity(0.4)' }
      },
      shake: {
        '0%, 100%': { transform: 'none' },
        '20%, 60%': { transform: 'rotate(-1deg) translateX(-3px)' },
        '40%, 80%': { transform: 'rotate(1deg) translateX(3px)' }
      },
      bounce: {
        '0%, 100%': {
          transform: 'translateY(-25%)',
          animationTimingFunction: 'cubic-bezier(0.8,0,1,1)'
        },
        '50%': {
          transform: 'none',
          animationTimingFunction: 'cubic-bezier(0,0,0.2,1)'
        }
      },
      rock: {
        '0%': { transform: 'rotate(-15deg)' },
        '100%': { transform: 'rotate(15deg)' }
      }
    },
    extend: {
      letterSpacing: {
        widest: '0.15em'
      },
      minWidth: theme => ({
        ...theme('spacing')
      }),
      maxWidth: theme => ({
        ...theme('spacing'),
        prose: '75ch',
        '1/12': '8.333333%',
        '2/12': '16.666667%',
        '3/12': '25%',
        '4/12': '33.333333%',
        '5/12': '41.666667%',
        '6/12': '50%',
        '7/12': '58.333333%',
        '8/12': '66.666667%',
        '9/12': '75%',
        '10/12': '83.333333%',
        '11/12': '91.666667%'
      }),
      outline: theme => ({
        auto: `1px auto ${theme('colors.black.DEFAULT', 'currentColor')}`
      }),
      gridTemplateColumns: {
        '2-auto-left': 'auto minmax(0, 100%)',
        '2-auto-right': 'minmax(0, 100%) auto',
        '3-auto-sides': 'auto minmax(0, 100%) auto'
      },
      transitionDelay: {
        0: '0ms'
      },
      zIndex: {
        '-1': '-1',
        '-2': '-2',
        '-3': '-3',
        1: '1',
        2: '2',
        3: '3',
        60: '60',
        70: '70',
        80: '80',
        90: '90'
      },
      lineHeight: {
        0: '0'
      },
      backdropBlur: {
        xs: '2px'
      },
      height: {
        '3em': '3em'
      },
      width: {
        '3em': '3em'
      }
    }
  },
  variants: {
    extend: {
      zIndex: ['hover'],
      brightness: ['hover', 'focus'],
      transitionProperty: ['active', 'focus-visible'],
      ringWidth: ['hover', 'active', 'focus-visible'],
      outline: ['focus-visible'],
      ringColor: ['hover', 'focus-visible', 'active', 'group-hover'],
      backgroundColor: ['active', 'odd', 'even'],
      textColor: ['active'],
      textDecoration: ['active', 'focus', 'focus-visible'],
      animation: ['hover'],
      translate: ['group-hover'],
      scale: ['responsive', 'hover', 'focus', 'active'],
      transitionDuration: ['responsive', 'active'],
      invert: ['hover', 'focus', 'active']
    }
  },
  plugins: [
    plugin(({ addUtilities, addComponents, theme }) => {
      const newUtilities = {
        '.aspect-portrait': {
          aspectRatio: '2 / 3'
        },
        '.aspect-photo': {
          aspectRatio: '3 / 2'
        },
        '.no-content': {
          content: "''"
        },
        '.visually-hidden': {
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: '1px',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px'
        },
        '.-mx-container': {
          marginLeft: 'calc(-1 * var(--veke_container-padding))',
          marginRight: 'calc(-1 * var(--veke_container-padding))'
        },
        '.gap-container': {
          gap: 'var(--veke_container-padding)'
        },
        '.p-container': {
          padding: 'var(--veke_container-padding)'
        },
        '.px-container': {
          paddingLeft: 'var(--veke_container-padding)',
          paddingRight: 'var(--veke_container-padding)'
        },
        '.py-container': {
          paddingTop: 'var(--veke_container-padding)',
          paddingBottom: 'var(--veke_container-padding)'
        },
        '.pt-container': {
          paddingTop: 'var(--veke_container-padding)'
        },
        '.pb-container': {
          paddingBottom: 'var(--veke_container-padding)'
        },
        '.joint-left-1': {
          clipPath: 'polygon(50% 17%, 0 17%, 0 83%, 100% 83%, 100% 100%, 0 100%, 0 0, 50% 0)'
        },
        '.joint-right-1': {
          clipPath: 'polygon(100% 0, 50% 0, 50% 17%, 0 17%, 0 83%, 100% 83%)'
        },
        '.joint-top-1': {
          clipPath: 'polygon(100% 0, 100% 50%, 83% 50%, 83% 0, 17% 0, 17% 100%, 0 100%, 0 0)'
        },
        '.joint-bottom-1': {
          clipPath: 'polygon(100% 0, 100% 50%, 83% 50%, 83% 0, 17% 0, 17% 100%, 83% 100%, 100% 100%)'
        },
        '.joint-top-2': {
          clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)'
        },
        '.joint-bottom-2': {
          clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)'
        },
        '.joint-right-2': {
          clipPath: 'polygon(0 0, 50% 0, 50% 50%, 100% 50%, 100% 100%, 0 100%)'
        },
        '.joint-left-2': {
          clipPath: 'polygon(0 0, 100% 0, 100% 50%, 50% 50%, 50% 100%, 0 100%)'
        }
      }
      const newComponents = {
        '#app .container': {
          maxWidth: theme('screens.xl'),
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: 'var(--veke_container-padding)',
          paddingRight: 'var(--veke_container-padding)'
        },
        '#app .container-grid-wrapper': {
          display: 'grid',
          gridTemplateColumns: '1fr repeat(4, minmax(0, 360px)) 1fr'
        },
        '.list-reset': {
          listStyle: 'none',
          padding: 0,
          margin: 0
        },
        '.list-reset > li': {
          margin: 0
        },
        '.wrap-balance': {
          textWrap: 'balance'
        }
      }
      addUtilities(newUtilities, {
        variants: ['responsive']
      })
      addComponents(newComponents, {
        variants: ['responsive']
      })
    })
  ]
}

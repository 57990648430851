<template>
  <filter-dropdown :label="filter.name">
    <template #selection>
      {{ preview }}
    </template>
    <section class="p-4">
      <div class="flex justify-between gap-6 font-bold">
        <div role="heading" class="uppercase">
          Leveys
        </div>
        <div class="text-right">
          0 &ndash; {{ value.width }} cm
        </div>
      </div>
      <input
        v-model.number="value.width"
        type="range"
        min="0"
        max="400"
        class="w-full h-10"
      />
    </section>
    <section class="p-4 mt-4">
      <div class="flex justify-between gap-6 font-bold">
        <div role="heading" class="uppercase">
          Korkeus
        </div>
        <div class="text-right">
          0 &ndash; {{ value.height }} cm
        </div>
      </div>
      <input
        v-model.number="value.height"
        type="range"
        min="0"
        max="400"
        class="w-full h-10"
      />
    </section>
    <section class="p-4 mt-4">
      <div class="flex justify-between gap-6 font-bold">
        <div role="heading" class="uppercase">
          Pituus
        </div>
        <div class="text-right">
          0 &ndash; {{ value.length }} cm
        </div>
      </div>
      <input
        v-model.number="value.length"
        type="range"
        min="0"
        max="400"
        class="w-full h-10"
      />
    </section>
  </filter-dropdown>
</template>

<script>
import FilterDropdown from '@/veke3000/components/filters/FilterDropdown.vue'
import {
  defineComponent, reactive, computed,
} from 'vue'

export default defineComponent({
  name: 'SizeFilter',
  components: {
    FilterDropdown,
  },
  inheritAttrs: false,
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const value = reactive({
      width: 100,
      height: 100,
      length: 100,
    })
    const preview = computed(() => {
      const values = Object.values(value).filter((s) => s > 0)
      return `Max. ${values.join(' × ')} cm`
    })
    return {
      value, preview,
    }
  },
})
</script>

import {
  createRouter, createWebHistory, RouteRecordRaw,
} from 'vue-router'
import Layout from '@/views/Layout.vue'
import Colors from '@/views/Colors.vue'
import Typography from '@/views/Typography.vue'
import Buttons from '@/views/Buttons.vue'
import Icons from '@/views/Icons.vue'
import Forms from '@/views/Forms.vue'
import Animations from '@/views/Animations.vue'
// Veke 3000
import Veke3000 from '@/veke3000/Veke3000.vue'
import Home from '@/veke3000/pages/Home.vue'
import Category from '@/veke3000/pages/Category.vue'
import Product from '@/veke3000/pages/Product.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'style-guide',
    component: Colors,
  },
  {
    path: '/layout/:layout?',
    name: 'layout',
    props: true,
    component: Layout,
  },
  {
    path: '/colors',
    name: 'colors',
    component: Colors,
  },
  {
    path: '/typography',
    name: 'typography',
    component: Typography,
  },
  {
    path: '/buttons',
    name: 'buttons',
    component: Buttons,
  },
  {
    path: '/icons',
    name: 'icons',
    component: Icons,
  },
  {
    path: '/forms',
    name: 'forms',
    component: Forms,
  },
  {
    path: '/animations',
    name: 'animations',
    component: Animations,
  },
  {
    path: '/veke3000',
    name: 'veke3000',
    component: Veke3000,
    meta: { layout: 'empty' },
    children: [
      { path: '', component: Home },
      { path: 'p/:slug', name: 'product', component: Product },
      { path: 'c/:slug', name: 'category', component: Category },
      {
        path: '/:pathMatch(.*)*',
        name: 'home',
        component: Home,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0, behavior: 'smooth' }
  },
})

export default router

module.exports = {
  black: {
    DEFAULT: '#000000',
    a10: 'rgba(0,0,0,0.1)',
    a20: 'rgba(0,0,0,0.2)',
    a30: 'rgba(0,0,0,0.3)',
    a40: 'rgba(0,0,0,0.4)',
    a50: 'rgba(0,0,0,0.5)',
    a60: 'rgba(0,0,0,0.6)',
    a70: 'rgba(0,0,0,0.7)',
    a80: 'rgba(0,0,0,0.8)',
    a90: 'rgba(0,0,0,0.9)'
  },
  white: {
    DEFAULT: '#FFFFFF',
    a10: 'rgba(255,255,255,0.1)',
    a20: 'rgba(255,255,255,0.2)',
    a30: 'rgba(255,255,255,0.3)',
    a40: 'rgba(255,255,255,0.4)',
    a50: 'rgba(255,255,255,0.5)',
    a60: 'rgba(255,255,255,0.6)',
    a70: 'rgba(255,255,255,0.7)',
    a80: 'rgba(255,255,255,0.8)',
    a90: 'rgba(255,255,255,0.9)'
  },
  gunmetal: {
    DEFAULT: '#2A363B',
    a60: 'rgba(42, 54, 59, 0.6)'
  },
  whitesmoke: '#f5f5f5',
  lotion: '#fbfbfb',
  gainsboro: '#DADAD9',
  snow: '#F1F7F9',
  slush: '#a5b9be',
  turquoise: {
    DEFAULT: '#56C2BD',
    d: '#32AAA4', // Deprecated: replaced with Rivulet
    l: '#7ECDCA' // Deprecated: replaced with Downy (was #7ECDCA)
  },
  amaranth: {
    DEFAULT: '#E73454',
    d: '#C92E4B', // Deprecated: replaced with Raspberry
    l: '#FF3B5F' // Deprecated: replaced with Radical
  },
  silk: '#F7D0C5',
  lavenderOld: '#FAEDEE',
  gold: '#F2B738', // was #F1B40B
  sunrise: '#FFDD00',
  peach: '#FFD478',
  lime: '#D5E384', // was #DEE37E
  space: '#222C30',
  charcoal: '#30424B',
  silver: '#B3B3B3',
  platinum: '#E6E6E6',
  rivulet: '#32AAA4',
  downy: '#7ECDCA',
  ruby: '#8F1828',
  raspberry: '#C92E4B',
  radical: '#FF3B5F',
  jade: '#00B561',
  citron: '#9BB133',
  android: '#ADC23A',
  june: '#C5D959',
  spring: '#EBF4B4',
  galliano: '#D8A007',
  papaya: '#FFF1D6'
}

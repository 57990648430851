import { onMounted } from 'vue'

const intersectClass = 'intersect'
const intersectingClass = 'intersecting'

function checkIntersection(entries, observer) {
  entries.forEach(({ target, isIntersecting }) => {
    if (!isIntersecting) {
      return
    }
    target.classList.add(intersectingClass)
    observer.unobserve(target)
  })
}
export default function () {
  onMounted(() => {
    const observer = new IntersectionObserver(
      checkIntersection,
      {
        threshold: 0.1,
      },
    )

    document.getElementsByClassName(intersectClass).forEach((el) => {
      observer.observe(el)
    })
  })
}

<template>
  <nav class="main-menu">
    <div class="main-menu-content">
      <sub-menu
        ref="submenu"
        :item="history"
        :transition="transition"
        @menu-item-opened="setCurrentMenu"
        @menu-back="back"
      />
      <section class="theme-gunmetal h-20 py-2 flex items-center">
        <base-button look="primary" class="theme-gunmetal w-12 h-12 px-0 fixed rounded-full top-4 right-4 z-30" @click="close">
          <x-icon />
        </base-button>
        <div class="container">
          <base-button class="text-12 px-4" look="secondary">
            <user-icon class="icon-mr" />Kirjaudu sisään
          </base-button>
        </div>
      </section>

      <section class="theme-default">
        <div class="container">
          <h3 class="beta">
            Tuotteet
          </h3>
          <menu-item
            v-for="item in categoryTree"
            :key="item.id"
            :item="item"
            @click="openSubmenu(item)"
          />
          <div class="pb-6">
            <ul class="text-12 list-reset grid grid-cols-2 gap-x-6 gap-y-4 py-4">
              <li>
                <base-button look="tertiary" class="" href="#">
                  Uutuudet
                </base-button>
              </li>
              <li>
                <base-button look="tertiary" class="" href="#">
                  Brändit
                </base-button>
              </li>
              <li>
                <base-button look="tertiary" class="" href="#">
                  Tuotesarjat
                </base-button>
              </li>
              <li>
                <base-button look="tertiary" class="" href="#">
                  Outlet
                </base-button>
              </li>
              <li>
                <base-button look="tertiary" class="" href="#">
                  Aina edulliset
                </base-button>
              </li>
              <li>
                <base-button look="tertiary" class="" href="#">
                  Lahjakortti
                </base-button>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="theme-lavender">
        <div class="container">
          <h3 class="beta">
            Inspiraatiota
          </h3>
          <div class="grid grid-cols-2 gap-4">
            <a href="#" class="link-unstyled block rounded shadow-sm overflow-hidden bg-white">
              <figure>
                <img src="@/assets/images/blogi.jpeg" alt="Blogi" class="h-48 w-full object-cover" />
                <figcaption class="delta text-center my-2">
                  Blogi
                </figcaption>
              </figure>
            </a>
          </div>
          <h4>Ideoita huoneisiin</h4>
        </div>

        <div class="grid grid-flow-col gap-4 py-4 overflow-x-auto px-container md:container justify-start">
          <image-link :image="require('@/assets/images/huoneet/olohuone_thumbnail_x2.jpg')" caption="Olohuone" class="w-48" />
          <image-link :image="require('@/assets/images/huoneet/keittio_thumbnail_x2.jpg')" caption="Keittiö" class="w-48" />
          <image-link :image="require('@/assets/images/huoneet/makuuhuone_thumbnail_x2.jpg')" caption="Makuuhuone" class="w-48" />
          <image-link :image="require('@/assets/images/huoneet/lastenhuone_thumbnail_x2.jpg')" caption="Lastenhuone" class="w-48" />
          <image-link :image="require('@/assets/images/huoneet/ulkotila_thumbnail_x2.jpg')" caption="Ulkotilat" class="w-48" />
          <div class="w-2 md:w-4"></div>
        </div>
      </section>
      <section class="theme-default">
        <div class="container">
          <h3 class="beta">
            Asiakaspalvelu
          </h3>

          <div class="pb-6">
            Ota yhteyttä<br>
            Tilaus- ja toimitusehdot<br>
            Tuotteen palautus<br>
            Seuraa tilausta<br>
          </div>
        </div>
      </section>
      <section class="bg-flax">
        <div class="container">
          <h3 class="beta">
            Myymälät
          </h3>

          <div class="pb-6">
            Vantaa<br>
            Rovaniemi<br>
            Oulu<br>
            Tampere<br>
          </div>
        </div>
      </section>
      <section class="theme-amaranth">
        <div class="container">
          <h3 class="beta">
            Veke
          </h3>
          <div class="pb-6">
            Meistä<br>
          </div>
        </div>
      </section>
      <div class="py-8 text-center">
        <base-button @click="close">
          <chevron-left-icon /> Sulje valikko
        </base-button>
      </div>
    </div>
  </nav>
</template>

<script>
import { categoryTree } from '@/veke3000/components/Categories'
import { useRouter } from 'vue-router'
import {
  defineComponent, ref, computed,
} from 'vue'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import MenuItem from '@/veke3000/components/mobile-menu/MenuItem.vue'
import ImageLink from '@/veke3000/components/ImageLink.vue'
import SubMenu from '@/veke3000/components/mobile-menu/SubMenu.vue'
import {
  XIcon,
  ChevronLeftIcon,
  UserIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

const mobileMenu = () => {
  const router = useRouter()
  const forward = ref(true)
  const transition = computed(() => (forward.value ? 'full-slide-left' : 'full-slide-right'))
  const history = ref([])
  const submenu = ref(null)
  const openSubmenu = (cat) => {
    forward.value = true
    history.value.push(cat)
    document.body.classList.add('submenu-open')
  }
  const close = () => {
    history.value = []
    document.body.classList.remove('menu-open')
  }
  const setCurrentMenu = (cat) => {
    forward.value = true
    submenu.value.$el.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (cat.children?.length) {
      history.value.push(cat)
    } else {
      router.push({ path: `/veke3000/c/${cat.slug}` })
      close()
    }
  }
  const back = () => {
    forward.value = false
    submenu.value.$el.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    history.value.pop()
    if (!history.value.length) {
      document.body.classList.remove('submenu-open')
    }
  }
  return {
    close, back, setCurrentMenu, openSubmenu, history, transition, submenu, categoryTree,
  }
}

export default defineComponent({
  name: 'SiteMenu',
  components: {
    SubMenu,
    BaseButton,
    MenuItem,
    ImageLink,
    XIcon,
    ChevronLeftIcon,
    UserIcon,
  },
  setup() {
    return mobileMenu()
  },
})
</script>

<style lang="scss">
.main-menu {
  @apply invisible w-screen h-screen fixed z-50 inset-y-0 -right-full overflow-y-auto overflow-x-hidden bg-white/80 w-screen;

  .main-menu-content {
    @apply shadow-lg absolute inset-x-0 min-h-screen transform translate-x-8 w-screen opacity-0;
    transition: transform 600ms, opacity 200ms;
  }
  section {
    @apply py-12;
  }
  &.submenu-open {
    @apply overflow-y-hidden;
  }
}

.menu-open {
  @apply overflow-hidden;
  .main-menu {
    @apply visible right-auto;
    .main-menu-content {
      @apply transform-none opacity-100;
    }
  }
}
</style>

<template>
  <h2>Jotain tiettyä mielessä?</h2>
  <search-field
    v-model="query"
    name="search-form-query"
    wrapper-class="text-20 sm:max-w-96"
    placeholder="Etsi yli 10 000 tuotteen valikoimasta..."
    hide-label
  />
</template>

<script>
import SearchField from '@bit/vekenkaluste.veke-ui.vue3.search-field'
import { ref } from 'vue'

export default {
  name: 'NewsletterForm',
  components: {
    SearchField,
  },
  setup() {
    const query = ref('')
    return {
      query,
    }
  },
}
</script>

<template>
  <transition :name="transition">
    <div v-if="current" class="submenu">
      <section class="submenu-header theme-gunmetal">
        <base-button look="secondary" class="theme-gunmetal w-12 h-12 px-0 rounded-full z-30 absolute left-4" @click="back">
          <chevron-left-icon />
        </base-button>
        <div class="container">
          <span class="text-10 text-center">
            <span v-if="previous">
              {{ previous.value }} /
            </span>
            <span v-else>
              Tuotteet /
            </span>
          </span>
          <h4 class="m-0" :class="textSize">
            {{ current.value }}
          </h4>
        </div>
      </section>
      <nav class="grid overflow-x-hidden">
        <transition :name="transition">
          <div :key="current.id" class="w-screen col-start-1 col-end-2 row-start-1 row-end-2">
            <div>
              <img
                class="w-full object-cover bg-snow"
                style="aspect-ratio: 1270 / 500"
                :src="require('@/assets/images/'+categoryImage)"
                alt=""
                aria-hidden="true"
              />
            </div>
            <div class="container">
              <div class="py-container pb-6">
                <menu-item
                  class="text-amaranth"
                  :item="{ value: 'Näytä kaikki', slug: current.slug, children: [] }"
                  @click="openMenuItem({ slug: '/veke3000/c/'+current.slug })"
                />
                <menu-item
                  v-for="cat in current.children"
                  :key="cat.id"
                  :item="cat"
                  @click="openMenuItem(cat)"
                />
                <div class="mt-8 py-6 text-center">
                  <base-button look="secondary" class="w-full" @click="back">
                    <chevron-left-icon class="icon-mr" /> Takaisin
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </nav>
    </div>
  </transition>
</template>

<script>
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import MenuItem from '@/veke3000/components/mobile-menu/MenuItem.vue'
import {
  ChevronLeftIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'SubMenu',
  components: {
    BaseButton,
    MenuItem,
    ChevronLeftIcon,
  },
  props: {
    item: {
      type: Array,
      required: true,
    },
    transition: {
      type: String,
      required: false,
      default: 'fade',
    },
  },
  emits: ['menu-item-opened', 'menu-back'],
  data() {
    return {
      forward: true,
    }
  },
  computed: {
    current() {
      return this.item[this.item.length - 1]
    },
    previous() {
      return this.item[this.item.length - 2]
    },
    textSize() {
      return this.current ? this.current.value.length > 28 ? 'text-10' : this.current.value.length > 24 ? 'text-12' : '' : ''
    },
    direction() {
      return this.forward ? 'short-slide-left' : 'fade'
    },
    categoryImage() {
      return this.current?.image || this.previous?.image || 'category_huonekalut.webp'
    },
  },
  methods: {
    openMenuItem(category) {
      this.forward = true
      this.$emit('menu-item-opened', category)
    },
    back() {
      this.forward = false
      this.$emit('menu-back')
    },
  },
}
</script>

<style lang="scss">
.submenu {
  @apply invisible left-full fixed inset-y-0 bg-white z-30 overflow-y-auto w-screen h-screen;
  transition: all 400ms;
  .submenu-header {
    @apply left-full top-0 w-screen sticky top-0 h-20 py-2 z-30 flex items-center text-center;
    transition: all 400ms;
  }
}
//.submenu-open {
body {
  .submenu {
    @apply visible left-0;
    .submenu-header {
      @apply left-0;
    }
  }
}
</style>

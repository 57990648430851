<template>
  <div class="bg-lavender">
    <div class="container max-w-lg">
      <main class="py-12">
        <div class="theme-gunmetal rounded p-8">
          <h1 class="beta mb-8">
            Kirjaudu sisään
          </h1>
          <form>
            <base-input
              id="l_email"
              name="l_email"
              class="border-transparent"
              wrapper-class="mb-6"
              type="email"
              label="Sähköpostiosoite"
              placeholder="Syötä sähköpostiosoitteesi"
            />
            <base-input
              id="l_passw"
              name="l_passw"
              class="border-transparent"
              type="password"
              label="Salasana"
            />
          </form>
        </div>
        <pre class="code mt-12">
&lt;div class="container max-w-lg">
  &lt;main>
    Content here
  &lt;/main>
&lt;/div></pre>
      </main>
    </div>
  </div>
</template>

<script>
import BaseInput from '@bit/vekenkaluste.veke-ui.input'

export default {
  components: { BaseInput },
}
</script>

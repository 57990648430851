<template>
  <div class="container">
    <h2>Jotain tiettyä mielessä?</h2>
    <p>
      <search-field
        v-model="query"
        name="product-search-homepage"
        hide-label
        wrapper-class="text-20 sm:max-w-96"
        placeholder="Etsi yli 10 000 tuotteen valikoimasta..."
      />
    </p>
  </div>
  <div class="xl:container">
    <div class="grid grid-flow-col gap-4 py-4 overflow-x-auto px-container xl:px-0 justify-start mt-8">
      <router-link
        v-for="category in popularCategories"
        :key="category.id"
        :to="'/veke3000/c/'+category.slug"
        class="search-suggestion"
      >
        <figure>
          <img :src="require('@/assets/images/'+getProductImage(category))" :alt="category.value" />
          <figcaption class="mt-2">
            {{ category.value }}
          </figcaption>
        </figure>
      </router-link>
      <div class="w-2"></div>
    </div>
  </div>
</template>

<script>
import { popularCategories } from '@/veke3000/components/Categories'
import SearchField from '@bit/vekenkaluste.veke-ui.vue3.search-field'
import { ref } from 'vue'

export default {
  name: 'Categories',
  components: {
    SearchField,
  },
  setup() {
    const query = ref('')
    const getProductImage = (cat) => cat.product || 'sohva.png'
    // const categoryProductImage = computed(() => props.menu?.image || 'category_huonekalut.webp')
    return { query, popularCategories, getProductImage }
  },
}
</script>

<style lang="scss" scoped>
.search-suggestion {
  @apply bg-white rounded-full flex items-center justify-center px-3 text-text font-bold;
  &:nth-of-type(4n+1) {
    //@apply bg-lavender;
  }
  &:nth-of-type(4n+2) {
    //@apply bg-papaya;
    border-radius: 0;
  }
  &:nth-of-type(4n+3) {
    //@apply bg-spring;
    border-radius: 0 50%;
  }
  &:nth-of-type(4n+4) {
    //@apply bg-snow;
    border-radius: 25%;
  }
  > figure {
    @apply text-center;
    > img {
      @apply w-20 h-20 object-contain object-bottom inline-block max-w-none;
    }
    > figcaption {

    }
  }
}
</style>

<template>
  <h2>Kaipaatko inspiraatiota?</h2>
  <div class="grid grid-flow-row sm:grid-flow-col sm:auto-cols-max gap-4">
    <email-field
      v-model="form.email"
      :validator="v$.email"
      name="newsletter-email"
      autocomplete="email"
      wrapper-class="sm:w-96"
    />
    <base-button @click="subscribe">
      Tilaa uutiskirje
    </base-button>
  </div>
</template>

<script>
import EmailField from '@bit/vekenkaluste.veke-ui.vue3.email-field'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import useVuelidate from '@vuelidate/core'
import { reactive } from 'vue'
import {
  helpers, required, email,
} from '@vuelidate/validators'

export default {
  name: 'NewsletterForm',
  components: {
    EmailField,
    BaseButton,
  },
  setup() {
    const form = reactive({ })
    const validations = {
      required: helpers.withMessage('Vaadittu kenttä', required),
      email: helpers.withMessage('Tarkista sähköpostiosoitteen muoto', email),
    }
    const rules = {
      email: {
        email: validations.email,
        required: validations.required,
      },
    }
    const v$ = useVuelidate(rules, form)
    const subscribe = async () => {
      await v$.value.$validate()
    }
    return {
      subscribe, v$, form,
    }
  },
}
</script>

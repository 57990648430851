<template>
  <nav class="megamenu text-14" :class="{'has-open-menu': openMenuClassActive}">
    <ul class="list-reset flex justify-between">
      <li
        v-for="alpha in categoryTree"
        :key="alpha.id"
        :class="{'menu-open': openedMenuId === alpha.id}"
      >
        <button
          :id="'menu-'+alpha.slug"
          :aria-expanded="openedMenuId === alpha.id ? 'true' : 'false'"
          class="btn btn-tertiary main-menu-button"
          @click="toggleSubMenu(alpha.id)"
        >
          {{ alpha.value }}
        </button>
        <mega-sub-menu :menu="alpha" :aria-labelledby="'menu-'+alpha.slug" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router'
import {
  defineComponent, ref, watch, computed,
} from 'vue'
import MegaSubMenu from '@/veke3000/components/mega-menu/MegaSubMenu.vue'
import { categoryTree } from '@/veke3000/components/Categories'

export default defineComponent({
  name: 'MegaMenu',
  components: {
    MegaSubMenu,
  },
  setup() {
    const route = useRoute()
    const openedMenuId = ref('')
    const openMenuClassActive = ref(false)
    const toggleSubMenu = (id) => {
      openedMenuId.value = (openedMenuId.value === id) ? '' : id
      setTimeout(() => { openMenuClassActive.value = !!openedMenuId.value }, 300)
    }
    const routePath = computed(() => route.path)
    watch(routePath, () => toggleSubMenu())
    return {
      openMenuClassActive, openedMenuId, toggleSubMenu, categoryTree,
    }
  },
})
</script>

<style lang="scss">
.btn.btn-tertiary.main-menu-button {
  @apply tracking-wider text-16 normal-case px-4 text-text;

  #app .megamenu .menu-open > & {
    @apply border text-amaranth;
  }
}
</style>

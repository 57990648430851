<template>
  <template v-if="$route.meta.layout === 'empty'">
    <router-view v-slot="slotProps">
      <component :is="slotProps.Component" />
    </router-view>
  </template>
  <template v-else>
    <header class="bg-white">
      <div class="container">
        <div class="text-center pt-4">
          <img
            src="@/veke-ui/assets/logo/veke-logo-black.svg"
            width="120"
            class="inline-block text-white"
            alt="Veke Logo"
          />
          <h1 class="py-4 uppercase text-light text-14 tracking-wider">
            Tyyliopas
          </h1>
        </div>
      </div>
    </header>
    <div class="bg-white py-4">
      <navigation :items="navigation" />
    </div>
    <div class="min-h-screen">
      <router-view v-slot="slotProps">
        <transition name="short-slide-left" mode="out-in">
          <component :is="slotProps.Component" class="mb-24" />
        </transition>
      </router-view>
    </div>
  </template>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Veke3000 from '@/veke3000/Veke3000.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Veke3000,
  },
  data() {
    return {
      navigation: [
        {
          name: 'Värit',
          path: 'colors',
        },
        {
          name: 'Typografia',
          path: 'typography',
        },
        {
          name: 'Painikkeet',
          path: 'buttons',
        },
        {
          name: 'Ikonit',
          path: 'icons',
        },
        {
          name: 'Lomakkeet',
          path: 'forms',
        },
        {
          name: 'Animaatiot',
          path: 'animations',
        },
        {
          name: 'Veke3000',
          path: 'veke3000',
        }
      ],
    }
  },
}
</script>

<style lang="scss" src="UI/scss/styles.scss"></style>

<template>
  <dropdown
    class="filter-dropdown"
    :button-class="['p-4 border-t lg:border lg:py-3 lg:rounded lg:inline-flex lg:w-auto lg:bg-white', { 'bg-white-glass-top font-bold lg:font-normal lg:shadow-md sticky top-0 lg:static': open }]"
    content-class="filter-dropdown-content overflow-y-auto pt-2 pb-4 px-4 lg:px-2 lg:py-4 lg:z-50 lg:absolute lg:border lg:rounded lg:mt-2 lg:bg-white lg:w-96 lg:shadow-lg"
    @dropdown-toggle="toggle"
  >
    <template #button>
      <div class="flex justify-between items-center gap-6">
        <span>
          {{ label }}
        </span>
        <span v-show="!open" class="text-12 text-rivulet lg:hidden min-w-0 whitespace-nowrap truncate">
          <slot name="selection"></slot>
        </span>
      </div>
    </template>
    <slot name="default"></slot>
  </dropdown>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Dropdown from '@bit/vekenkaluste.veke-ui.dropdown'

export default defineComponent({
  name: 'FilterDropdown',
  components: { Dropdown },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  setup() {
    const open = ref(false)
    function toggle(status) {
      open.value = status
    }
    return { open, toggle }
  },
})
</script>

<style lang="scss">
.filter-dropdown {
  &-content {
    @screen md {
      max-height: max(300px, 70vh);
    }

    .base-checkbox {
      @apply h-auto;
      input {
        + label {
          @apply flex px-4 py-3;
          &:hover {
            @apply bg-lotion;
          }
        }
        &:checked + label  {
          @apply bg-snow;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container py-container theme-amaranth store-background">
    <h2>Myymälät</h2>
    <div class="grid gap-4 lg:grid-flow-col">
      <div class="theme-default shadow-md rounded p-4">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Vantaa
          </h3>
          <div class="flex items-center">
            <span class="mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="xs:flex justify-between leading-loose">
          <address class="pb-4 xs:pb-0 xs:pr-4 sm:pr-6">
            Porttipuiston Liikekeskus,<br>
            Porttisuontie 4,<br>
            01200 Vantaa
          </address>
          <dl class="key-value-pair gap-y-0">
            <dt>ma-pe</dt>
            <dd>10-20</dd>
            <dt>la</dt>
            <dd>10-18</dd>
            <dt>su</dt>
            <dd>12-18</dd>
          </dl>
        </div>
      </div>

      <!-- -->
      <div class="theme-default shadow-md rounded p-4">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Oulu
          </h3>
          <div class="flex items-center">
            <span class="mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="xs:flex justify-between leading-loose">
          <address class="pb-4 xs:pb-0 xs:pr-4 sm:pr-6">
            Porttipuiston Liikekeskus,<br>
            Porttisuontie 4,<br>
            01200 Vantaa
          </address>
          <dl class="key-value-pair gap-y-0">
            <dt>ma-pe</dt>
            <dd>10-20</dd>
            <dt>la</dt>
            <dd>10-18</dd>
            <dt>su</dt>
            <dd>12-18</dd>
          </dl>
        </div>
      </div>
      <!-- -->
      <div class="theme-default shadow-md rounded p-4">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Rovaniemi
          </h3>
          <div class="flex items-center">
            <span class="mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="xs:flex justify-between leading-loose">
          <address class="pb-4 xs:pb-0 xs:pr-4 sm:pr-6">
            Porttipuiston Liikekeskus,<br>
            Porttisuontie 4,<br>
            01200 Vantaa
          </address>
          <dl class="key-value-pair gap-y-0">
            <dt>ma-pe</dt>
            <dd>10-20</dd>
            <dt>la</dt>
            <dd>10-18</dd>
            <dt>su</dt>
            <dd>12-18</dd>
          </dl>
        </div>
      </div>
    </div>
    <h2>Noutopisteet</h2>
    <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
      <div class="theme-gunmetal p-4 rounded">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Lahti
          </h3>
          <div class="flex items-center">
            <span class="hidden md:mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="leading-loose">
          Tänään 10-18
        </div>
      </div>
      <!-- -->
      <div class="theme-gunmetal p-4 rounded">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Oulu
          </h3>
          <div class="flex items-center">
            <span class="hidden md:mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="leading-loose">
          Tänään 10-18
        </div>
      </div>
      <!-- -->
      <div class="theme-gunmetal p-4 rounded">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Ranua
          </h3>
          <div class="flex items-center">
            <span class="hidden md:mr-2 md:inline text-12 lg:text-14">
              Suljettu
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-error">
            </span>
          </div>
        </div>
        <div class="leading-loose">
          Tänään 8-14
        </div>
      </div>
      <!-- -->
      <div class="theme-gunmetal p-4 rounded">
        <div class="flex justify-between mb-2">
          <h3 class="mb-0">
            Vantaa
          </h3>
          <div class="flex items-center">
            <span class="hidden md:mr-2 md:inline text-12 lg:text-14">
              Avoinna
            </span><span class="inline-block rounded-full w-3 h-3 mr-2 bg-success">
            </span>
          </div>
        </div>
        <div class="leading-loose">
          Tänään 10-18
        </div>
      </div>
      <!-- -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Stores',
}
</script>

<style lang="scss" scoped>
.store-background {
  //background: linear-gradient(205deg, #29363a 0%, #29363a 50%, #e73454 50%, #e73454 100%);
  background: linear-gradient(205deg, #F1F7F9 -30%, #7ECDCA 130%);
  //background-image: url('~@/assets/images/lapset.jpg');
  //background-size: cover;
  //background-position: top center;
}
</style>

import { categories, getBreadcrumbsByCategoryId } from '@/veke3000/components/Categories'

const productsFromAlgolia = require('@/assets/products.json')

const products = productsFromAlgolia.hits

const formatPrice = (price) => parseFloat(price).toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' })

const getBreadcrumbsByProductSku = (sku) => getBreadcrumbsByCategoryId(sku)

const getBread = (product) => {
  const deepestCategory = categories.find((cat) => cat.slug === product.categories[product.categories.length - 1].slug)
  const bread = getBreadcrumbsByCategoryId(deepestCategory.id)
  bread.push({ slug: product.slug, name: product.name, id: product.sku })
  return bread
}

const getProductBySlug = (slug) => {
  const product = products.find((p) => p.Slug?.value === slug)
  return {
    name: product.ProductName?.value || '???',
    fullName: product.ItemName?.value || '???',
    variationName: product.ItemName?.value.replace(product.ProductName?.value, '').trim() || '???',
    description: product.ProductDescription?.value || 'Ei kuvausta.',
    shortDescription: product.ProductShortDescription?.value || 'Ei kuvausta.',
    brand: product.ProductManufacturer?.value[0]?.value || 'idunno',
    image: product.ProductImage?.value,
    imageSecondary: product.ItemImages?.value[product.ItemImages.value.length - 1].url,
    stock: parseInt(product.ItemStock?.value, 10) || 0,
    categories: product.ProductCategories?.value,
    sku: product.ItemSKU?.value,
    default: product.ItemIsDefault?.value,
    images: product.ItemImages?.value,
    status: product.ProductStatus?.value,
    visibility: product.ProductVisibility?.value,
    slug: product.Slug?.value,
    price: formatPrice(product.ItemSum?.value || 0),
    pixmoor: product.ItemPixmoorUrl?.value,
  }
}

const productTileData = products.map((product) => ({
  name: product.ProductName?.value || '???',
  brand: product.ProductManufacturer?.value[0]?.value || 'idunno',
  image: product.ProductImage?.value,
  imageSecondary: product.ItemImages?.value[product.ItemImages.value.length - 1].url,
  stock: product.ItemStock?.value,
  sku: product.ItemSKU?.value,
  default: product.ItemIsDefault?.value,
  images: product.ItemImages?.value,
  status: product.ProductStatus?.value,
  visibility: product.ProductVisibility?.value,
  slug: product.Slug?.value,
  price: formatPrice(product.ItemSum?.value || 0),
  pixmoor: product.ItemPixmoorUrl?.value,
}))

const newProductTiles = productTileData.slice(0, 8)

export {
  products,
  getBread,
  getProductBySlug,
  getBreadcrumbsByProductSku,
  productTileData,
  newProductTiles,
}

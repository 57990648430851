<template>
  <div>
    <div class="bg-flax">
      <div class="container flex items-center justify-left h-32">
        <h1>Veke-verkkokaupan sopimusehdot</h1>
      </div>
    </div>
    <div class="bg-flax">
      <nav id="breadcrumbs" class="container py-4">
        Etusivu / Asiakaspalvelu / Ehdot
      </nav>
    </div>
    <div class="container-grid my-12">
      <aside class="sidebar">
        <div class="theme-peach rounded p-8">
          <div class="gamma">
            Asiakaspalvelu
          </div>
          <nav>
            <ul
              class="overflow-x-auto gap-4 whitespace-nowrap ml-0 p-4 list-none grid grid-flow-col lg:ml-5 lg:p-0 lg:block lg:list-disc lg:overflow-visible lg:whitespace-normal"
            >
              <li>Ehdot</li>
              <li>Maksutavat</li>
              <li>Selosteet</li>
              <li>Toimitustavat ja keskusvarastot</li>
              <li>Palautukset ja reklamaatiot</li>
              <li>Yhteystiedot</li>
            </ul>
          </nav>
        </div>
      </aside>
      <main class="content">
        <div class="bg-white">
          <h2>1. Tilaaminen ja sopimuksen tekeminen</h2>
          <p>
            <ul class="list-none mx-0">
              <li>
                1.1. Tilaaminen tapahtuu Veke.fi verkkokaupasta, puhelimella tai
                sähköpostilla.
              </li>
              <li>
                1.2. Tilauksessa asiakkaan tulee ilmoittaa nimi, osoite,
                puhelinnumero ja sähköpostiosoite. Ostoskorissa ovat valmiiksi
                näkyvillä tilattavaksi halutut tuotteet ja hinnat, maininta
                maksutavasta ja kotiinkuljetuksesta.
              </li>
              <li>
                1.3. Asiakkaan lähetettyä tilauksen on tehty sopimus sitova.
                Tilauksen lähettäminen katsotaan sitovaksi sopimukseksi
                molemminpuolisesti, eikä sitä kumpikaan osapuoli voi yksipuolisesti
                muuttaa. Molemmat osapuolet ovat sitoutuneet noudattamaan
                sopimushetkellä voimassa olleita tilaus- ja sopimusehtoja kaikilta
                osin. Tilauksen tehtyään asiakkaan on katsottu hyväksyneen tilaus-
                ja sopimusehdot, tuotteen ominaisuudet, voimassa olleet tarjoukset
                ja toimitusajat ja ymmärtäneen informaatiokuvakkeiden sisällön,
                esimerkiksi sen, että tuotteen väri voi luonnossa poiketa
                nettikaupan sivuilla esitellyistä värimalleista.
              </li>
              <li>
                1.4. Veken Kaluste on sitoutunut toimittamaan sopimuksen mukaisen
                tuotteen voimassa olleiden hintojen, ominaisuuksien ja tilausehtojen
                mukaisesti. Veken Kaluste on velvollinen ilmoittamaan asiakkaalle
                kuluttajansuojalain mukaisesti viipymättä, jos tuotteen ominaisuudet
                tai toimitusajat ovat muuttuneet sivustoilla sopimushetkellä
                ilmoitetusta poikkeaviksi. Tällöin asiakkaalla on myös oikeus
                tuotteen vaihtamiseen tai tilauksen perumiseen.
              </li>
            </ul>
          </p>
          <canvas class="bg-lime h-64 w-full"></canvas>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto,
            tempore quibusdam iure itaque commodi voluptatibus ipsa quia et, atque
            porro possimus labore est consequatur esse voluptatum deserunt
            sapiente. Repudiandae, soluta.
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto,
            tempore quibusdam iure itaque commodi voluptatibus ipsa quia et, atque
            porro possimus labore est consequatur esse voluptatum deserunt
            sapiente. Repudiandae, soluta.
          </p>
          <pre class="code">
&lt;div class="container-grid">
  &lt;aside class="sidebar">
    Sidebar content here
  &lt;/aside>
  &lt;main class="content">
    Main content here
  &lt;/main>
&lt;/div></pre>
          <p>HUOM: Vain yksi main-elementti per sivu. Käytä divejä tai article-elementtejä tarvittaessa.</p>
          <p>Sivupalkin voi siirtää oikeaan laitaan siirtämällä elementin DOMissa sisällön jälkeen.</p>
        </div>
      </main>
    </div>
  </div>
</template>

<template>
  <product-tile
    v-for="product in products"
    :key="product.sku"
    :product="product"
    class="w-full"
  />
</template>

<script>
import ProductTile from '@/veke3000/components/ProductTile.vue'

export default {
  name: 'ProductList',
  components: {
    ProductTile,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="product p-2 sm:p-4 lg:p-6">
    <div class="">
      <div class="p-4 h-0 relative" style="padding-bottom:100%;">
        <div class="z-20 absolute flex items-center flex-row-reverse justify-between inset-x-0 bottom-0">
          <base-button
            :state="buttonState"
            look="tertiary"
            transition="zoom-in"
            class="w-12 h-12 p-0 rounded-full theme-whitesmoke"
            @click="buttonState = (buttonState !== 'added') ? 'added' : 'default'"
          >
            <template #default>
              <heart-icon size="1.5x" />
            </template>
            <template #added>
              <heart-icon fill="currentColor" size="1.5x" />
            </template>
          </base-button>
          <div class="py-1 pointer-events-none text-10 sm:text-12 flex flex-wrap gap-1 whitespace-nowrap">
            <div class="inline-block theme-amaranth leading-loose py-0 px-2 font-bold">
              -15%
            </div>
            <div v-if="product.brand === 'Veke'" class="inline-block theme-peach leading-loose py-0 px-2 font-bold">
              Superpäivä
            </div>
          </div>
        </div>
        <router-link :to="'/veke3000/p/'+product.slug">
          <img
            :src="product.image"
            width="400"
            height="400"
            loading="lazy"
            class="absolute inset-0 w-full h-full max-w-full object-contain"
            alt=""
          />
          <img
            :src="product.imageSecondary"
            width="400"
            height="400"
            loading="lazy"
            class="absolute inset-0 bg-white w-full h-full max-w-full object-contain secondary-image"
            alt=""
          />
        </router-link>
      </div>
    </div>
    <div class="grid py-3 gap-3 lg:gap-4 border-t-0 border-gunmetal leading-loose bg-white">
      <div class="flex" :class="{ 'text-gainsboro': product.brand !== 'Veke', 'text-sunrise': product.brand === 'Veke'}">
        <star-icon
          v-for="i in 5"
          :key="i"
          size="12"
          :stroke-width="product.brand === 'Veke' ? 1 : 0"
          stroke="#000"
          fill="currentColor"
        />
      </div>
      <div class="font-bold text-12 sm:text-14 text-text">
        <div class="mt-auto">
          <div class="font-bold text-10 sm:text-12 uppercase text-muted tracking-wider">
            {{ product.brand }}
          </div>
          <router-link :to="product.slug" class="link-unstyled block h-8 sm:h-10 line-clamp-2 overflow-ellipsis">
            {{ product.name }}
          </router-link>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-2 gap-y-1">
        <span class="price text-amaranth leading-none w-full sm:w-auto">
          {{ product.price }}
        </span>
        <span class="price text-muted font-normal text-12">
          (1199,90 €)
        </span>
      </div>
      <div class="flex gap-2">
        <div class="w-6 h-6 rounded-full" style="background: #835339"></div>
        <div class="w-6 h-6 rounded-full" style="background: #000"></div>
        <div class="w-6 h-6 rounded-full border" style="background: #FFF"></div>
        <div class="w-6 h-6 rounded-full border text-10 inline-flex items-center justify-center">
          +4
        </div>
      </div>
      <div class="text-10 uppercase">
        <span>{{ product.stock > 2 ? 'Varastossa' : 'Tilaustuote' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import {
  HeartIcon,
  StarIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'ProductTile',
  components: {
    BaseButton,
    HeartIcon,
    StarIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const buttonState = ref('default')
    return { buttonState }
  },
}
</script>

<style lang="scss" scoped>
  .product {
    @apply block no-underline text-text bg-white rounded relative hover:shadow-md text-left;
    width: clamp(200px, 40vw, 310px);
    max-width: 100%;

    .secondary-image {
      @apply z-10 opacity-0;// transition transition-all duration-1000;
      transition: none;
      //filter: brightness(140%);
      transform: translateY(10px);
    }

    &:hover {
      .secondary-image {
        @apply opacity-100;
        transform: none;
        transition: all 400ms 0ms;
        //filter: none;
      }
    }
  }
</style>

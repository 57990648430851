<template>
  <div>
    <template v-if="category">
      <filter-menu v-if="true" />
      <hero-content :image="require('@/assets/images/'+categoryImage)">
        <div class="mb-2 lg:mb-6">
          <breadcrumbs :breadcrumbs="crumbs" />
        </div>
        <h1>{{ category.value }}</h1>
        <p v-if="category.description" class="pb-4 lg:pb-0">
          {{ category.description }}
        </p>
      </hero-content>
      <section>
        <subcategories :categories="category?.children" />
      </section>
      <section class="bg-snow py-6">
        <div class="container lg:hidden">
          <base-button class="flex-none w-full my-4" @click="openFilterMenu">
            <sliders-icon size="1.5x" class="icon-mr" /><span>Rajaa</span>
          </base-button>
        </div>
        <desktop-filters class="hidden lg:block" />
        <div class="lg:container lg:mt-4">
          <filter-selections />
        </div>
      </section>
      <section class="bg-white">
        <div class="container py-4">
          <div class="flex justify-between items-center">
            <span class="uppercase text-muted">
              {{ products.length }} tuotetta
            </span>
            <base-select
              v-model="sort"
              name="sort-by"
              label="Järjestä"
              hide-label
              :options="sortOptions"
              wrapper-class="text-14"
            />
          </div>
        </div>
      </section>
      <section class="py-6">
        <div class="container z-10 relative">
          <div class="grid grid-flow-row gap-4 md:gap-8 lg:gap-12 justify-start xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <product-list :products="products" />
          </div>
        </div>
        <div class="container flex justify-center py-8">
          <pagination
            :item-amount="682"
            :current="page"
            :per-page="48"
            @page-change="changePage"
          />
        </div>
      </section>
    </template>
    <template v-else>
      <h2>Kategoriaa ei löytynyt.</h2>
    </template>
    <div v-if="false" class="p-2 text-12 rounded sticky bottom-0 theme-peach z-50 inline-block">
      <h5 class="text-12">
        Kategoria
      </h5>
      <input v-model.lazy="categoryId" list="cats" type="search" />
      <datalist id="cats">
        <option v-for="cat in categories" :key="cat.id" :value="cat.id">
          {{ cat.value }}
        </option>
      </datalist>
    </div>
  </div>
</template>

<script>
import Pagination from '@bit/vekenkaluste.veke-ui.pagination'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import BaseSelect from '@bit/vekenkaluste.veke-ui.select'
import Breadcrumbs from '@bit/vekenkaluste.veke-ui.breadcrumbs'
import FilterMenu from '@/veke3000/components/filters/FilterMenu.vue'
import HeroContent from '@/veke3000/components/HeroContent.vue'
import ProductList from '@/veke3000/components/ProductList.vue'
import Subcategories from '@/veke3000/components/Subcategories.vue'
import DesktopFilters from '@/veke3000/components/filters/DesktopFilters.vue'
import FilterSelections from '@/veke3000/components/filters/FilterSelections.vue'
import {
  categories, getCategoryBySlug, getCategoryTreeById, getBreadcrumbsByCategoryId,
} from '@/veke3000/components/Categories'
import { productTileData as products } from '@/veke3000/components/Products'
import {
  ref, computed,
} from 'vue'
import { SlidersIcon } from '@bit/vekenkaluste.veke-ui.icons'
import { useRoute } from 'vue-router'

export default {
  name: 'Category',
  components: {
    BaseButton, BaseSelect, HeroContent, Breadcrumbs, ProductList, Pagination, Subcategories, FilterMenu, SlidersIcon, DesktopFilters, FilterSelections,
  },
  setup() {
    const sort = ref('mostPopular')
    const sortOptions = [
      { value: 'mostPopular', label: 'Suosituimmat' },
      { value: 'mostViewed', label: 'Katsotuimmat' },
      { value: 'newest', label: 'Uusin ensin' },
      { value: 'alphabetical', label: 'Tuotenimi' },
      { value: 'lowestPrice', label: 'Halvin ensin' },
      { value: 'highestPrice', label: 'Kallein ensin' },
    ]
    const page = ref(1)
    const route = useRoute()
    const cat = computed(() => getCategoryBySlug(route.params.slug))
    const categoryId = computed(() => cat.value.id)
    const openFilterMenu = () => { document.body.classList.add('filter-menu-open') }
    const category = computed(() => getCategoryTreeById(categoryId.value))
    const categoryImage = computed(() => category.value?.image || 'category_huonekalut.webp')
    const crumbs = computed(() => getBreadcrumbsByCategoryId(categoryId.value))
    const changePage = (p) => {
      page.value = p
      // TODO: router.push { query: { sivu: p } }
    }
    return {
      category, categoryImage, crumbs, page, changePage, categoryId, categories, sort, sortOptions, products, openFilterMenu,
    }
  },
}
</script>

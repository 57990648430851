<template>
  <div class="container">
    <h2>Kaipaatko inspiraatiota?</h2>
  </div>
  <div class="lg:container">
    <ul class="border-b-2 border-gunmetal list-none px-0 m-0 grid grid-flow-col gap-4 py-4 overflow-x-auto px-4 md:px-8 lg:px-0 justify-start">
      <li><button>Olohuone</button></li>
      <li><button>Ruokailutila</button></li>
      <li><button>Eteinen</button></li>
      <li><button>Ulkotila</button></li>
      <li><button>Lastenhuone</button></li>
    </ul>
    <div class="inspiration-images grid grid-flow-col gap-4 py-4 overflow-x-auto px-4 md:px-8 lg:px-0 justify-start items-center">
      <img src="@/assets/images/lapset.jpg" loading="lazy" alt="" />
      <img src="@/assets/images/sohva-miljoo.webp" loading="lazy" alt="" />
      <img src="@/assets/images/sohva.png" loading="lazy" alt="" />
      <img src="@/assets/images/myymala.webp" loading="lazy" alt="" />
      <img src="@/assets/images/sohva-miljoo.webp" loading="lazy" alt="" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Inspiration',
}
</script>

<style lang="scss" scoped>
.inspiration-images {
  > img {
    //max-width: 88vw;
    max-width: none;
    max-height: 66vh;
  }
}
</style>

<template>
  <div>
    <section class="theme-lavender pb-8">
      <hero-content :image="require('@/assets/images/fiiliskuva_1.jpg')">
        <div class="py-8 lg:py-16">
          <h1 class="text-amaranth">
            Tervetuloa sisustajan verkkokauppaan!
          </h1>
          <p class="lead my-4">
            Veke on kotimainen perheyritys, joka tarjoaa ratkaisuja kodin sisustajan jokaiseen elämänvaiheeseen.
          </p>
          <figure class="my-8 lg:col-start-6">
            <img
              src="@/assets/images/ole-kuin-kotonasi.png"
              loading="lazy"
              width="200"
              alt="Ole kuin kotonasi"
            />
          </figure>
        </div>
      </hero-content>
    </section>
    <section class="theme-lavender py-8 tarjoukset intersect mb-12">
      <campaigns />
    </section>
    <section class="theme-default py-8 mieli intersect mb-12">
      <categories />
    </section>
    <section class="theme-default py-8 uutuus intersect mb-12">
      <new-products />
    </section>
    <section class="bg-papaya py-8 intersect mb-12">
      <inspiration />
    </section>
    <section class="mb-12">
      <stores />
    </section>
  </div>
</template>

<script>
import Intersect from '@/veke3000/components/Intersect'
import Inspiration from '@/veke3000/components/home/Inspiration.vue'
import Campaigns from '@/veke3000/components/home/Campaigns.vue'
import Categories from '@/veke3000/components/home/Categories.vue'
import Stores from '@/veke3000/components/home/Stores.vue'
import HeroContent from '@/veke3000/components/HeroContent.vue'
import NewProducts from '@/veke3000/components/home/NewProducts.vue'

export default {
  components: {
    Inspiration,
    Campaigns,
    Categories,
    Stores,
    HeroContent,
    NewProducts,
  },
  setup() {
    const int = Intersect()
    return { int }
  },
}
</script>

<style lang="scss">
#app {
  /* Intersecting */
  .intersect h2 {
    transition: transform 1s, opacity 1s;
    transform: rotateX(-30deg) translateY(-10px);
    opacity: 0;
  }
  .intersecting h2 {
    transform: none;
    opacity: 1;
  }
}
/*
.carousel::-webkit-scrollbar {
  display: none;
}
*/
</style>

<template>
  <div>
    <section class="lg:mb-4">
      <div class="container">
        <breadcrumbs :breadcrumbs="bread" />
      </div>
    </section>
    <section>
      <div class="lg:container">
        <div class="grid gap-x-12 gap-y-6 grid-flow-row grid-cols-1 lg:grid-flow-col lg:grid-cols-2">
          <div class="relative">
            <div class="sticky top-4">
              <div class="px-8 lg:rounded lg:border">
                <div class="absolute z-2 top-4 right-4 theme-peach font-bold grid-flow-row px-2 py-1 text-12 lg:px-3 md:py-2 md:text-14">
                  Superpäivä
                </div>
                <div class="h-0 relative" style="padding-bottom:100%;">
                  <img :src="product.image" alt="" class="absolute inset-0 w-full h-full max-w-full object-contain" />
                </div>
              </div>
              <div class="bg-snow lg:bg-white">
                <div class="p-4 lg:px-px lg:pb-px flex justify-start max-w-full lg:flex-wrap overflow-x-auto gap-4">
                  <div
                    v-for="thumbnail in product.images"
                    :key="thumbnail"
                    class="aspect-w-1 aspect-h-1 h-20 w-20 border rounded relative flex-none bg-white border border-white"
                    :class="{'shadow-md ring-1 ring-turquoise': thumbnail.isMainImage }"
                    style="border-width: 6px"
                  >
                    <img :src="thumbnail.url" alt="" class="absolute inset-0 w-full h-full max-w-full object-contain" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="grid grid-flow-row gap-x-12 gap-y-6 md:grid-cols-2">
              <div class="md:col-span-2 order-1 container lg:px-0">
                <h1 class="visually-hidden">
                  {{ product.fullName }}
                </h1>
                <div class="beta mt-0 mb-2">
                  {{ product.name }}
                </div>
                <h1 class="text-12 text-muted">
                  {{ product.variationName }}
                </h1>
              </div>
              <div class="md:row-span-2 order-7 md:order-3 md:pr-4 lg:pr-0">
                <div class="bg-snow rounded p-4 text-14">
                  <stock-status :qty="product.stock" class="mb-4" />
                  <p>Toimitusaika 3-6 arkipäivää</p>
                  <dropdown
                    label="Toimitus alkaen 15,90 €"
                    class="-m-2 rounded hover:bg-white"
                    closed-wrapper-class=""
                    open-wrapper-class=""
                    button-class="text-left p-2 rounded"
                    open-button-class="bg-white lg:shadow-md"
                    content-class="filter-dropdown-content overflow-y-auto text-12 lg:text-16 p-2 lg:p-4 lg:z-50 lg:absolute lg:right-0 lg:border rounded-b lg:rounded lg:mt-2 bg-white lg:w-96 lg:shadow-lg"
                  >
                    <template #icon>
                      <info-icon size="1.5x" class="ml-4 flex-shrink-0" />
                    </template>
                    <dl class="key-value-pair hl-value w-full">
                      <dt>Postipaketti, Nouto lähimmästä Postista:</dt>
                      <dd class="price">
                        15,90 €
                      </dd>
                      <dt>Posti Kotipaketti, kuljetus asunnon ovelle:</dt>
                      <dd class="price">
                        29,90 €
                      </dd>
                      <dt>Toimitus myymälään tai noutopisteelle:</dt>
                      <dd class="price">
                        Ilmainen
                      </dd>
                    </dl>
                  </dropdown>
                </div>
              </div>
              <div id="price" class="order-2 container lg:px-0">
                <div class="price-row flex flex-wrap gap-2 items-baseline lg:items-center">
                  <div class="order-1 lg:w-full text-24 md:text-36 price text-amaranth md:tracking-normal">
                    {{ product.price }}
                  </div>
                  <div class="order-2 lg:order-3 text-muted text-12 lg:text-14 tracking-wide">
                    <span class="in-parenthesis flex">
                      <span class="hidden lg:inline mr-1">
                        norm.
                      </span>
                      <span>{{ product.price }}</span>
                    </span>
                  </div>
                  <div class="order-3 lg:order-2 lg:ml-0 ml-auto self-center text-14 px-3 py-1 flex items-center justify-center font-bold text-white bg-amaranth text-center">
                    -25%
                  </div>
                </div>
                <p class="mb-0 text-14">
                  Tarjous voimassa 24.12 asti!
                </p>
              </div>
              <div id="pallurat" class="hidden rder-3 md:order-4 container lg:px-0 bg-snow py-4 grid grid-cols-4 gap-4">
                <div class="flex flex-wrap items-center text-center justify-center text-10 border bg-white rounded-full" style="aspect-ratio: 1 / 1">
                  <div>
                    <span class="text-14 font-bold">
                      64 €/kk
                    </span><br />OSAMAKSU
                  </div>
                </div>
                <div class="flex flex-wrap items-center text-center justify-center text-10 border bg-white rounded-full" style="aspect-ratio: 1 / 1">
                  <div>
                    <span class="text-14 font-bold">
                      alk. 15,90 €
                    </span><br />TOIMITUS
                  </div>
                </div>
                <div class="flex flex-wrap items-center text-center justify-center text-10 border bg-white rounded-full" style="aspect-ratio: 1 / 1">
                  <div>
                    <span class="text-14 font-bold">
                      4.5
                    </span><br />ARVOSTELUT
                  </div>
                </div>
                <div class="flex flex-wrap items-center text-center justify-center text-10 border bg-white rounded-full" style="aspect-ratio: 1 / 1">
                  <div>
                    <span class="text-14 font-bold">
                      yli 10
                    </span><br />VARASTOSSA
                  </div>
                </div>
              </div>
              <div id="klarna" class="order-3 md:order-4 container lg:px-0">
                <div class="border rounded text-center py-3 px-4">
                  Maksa 64 €/kk 6 kuukautta<br>
                  <b>Klarna</b>. <a href="#">
                    Lisätietoja
                  </a><br>
                  <div class="text-muted text-14 leading-tight">
                    6 kuukautta, kokonaissumma 380,49 €, tod. vuosikorko 21,75%
                  </div>
                </div>
              </div>
              <div id="stars" class="order-4 md:order-5 inline-flex items-center gap-4 container lg:px-0">
                <div>
                  <span class="text-sunrise">
                    <star-icon stroke="black" fill="currentColor" stroke-width="1" />
                    <star-icon stroke="black" fill="currentColor" stroke-width="1" />
                    <star-icon stroke="black" fill="currentColor" stroke-width="1" />
                    <star-icon stroke="black" fill="currentColor" stroke-width="1" />
                  </span>
                  <star-icon stroke="grey" stroke-width="1" />
                </div>
                <div>
                  <a href="#">
                    8 suosittelua
                  </a>
                </div>
              </div>
              <div id="description" class="md:col-span-2 order-5 md:order-6 container lg:px-0">
                <div v-html="product.shortDescription" />
              </div>
              <div id="selections" class="container lg:px-0 md:col-span-2 order-6 md:order-7">
                <div class="border rounded p-4 h-40">
                  VALINNAT
                </div>
              </div>
              <div id="quantity" class="md:col-span-2 order-8 container lg:px-0 grid-flow-col flex flex-wrap gap-x-8 gap-y-4">
                <number-field
                  v-model="productQty"
                  wrapper-class="text-18 w-40"
                  name="product-qty"
                  :min="1"
                  :step="1"
                  :max="99"
                />
                <base-button class="text-18 w-full md:w-auto">
                  <cart-icon size="1.5x" class="icon-mr" /> Lisää ostoskoriin
                </base-button>
              </div>
              <div class="md:col-span-2 order-9">
                <dropdown
                  label="Tarkemmat saatavuustiedot"
                  class="rounded border hover:bg-white"
                  closed-wrapper-class=""
                  open-wrapper-class="shadow-md"
                  button-class="text-left p-4"
                  open-button-class="font-bold"
                  content-class="text-12 lg:text-16 bg-white p-4 pt-0"
                >
                  asd
                </dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12">
      <div class="container">
        <div class="bg-snow p-4 text-center mb-8">
          <ul class="list-reset inline-grid gap-4 grid-flow-col text-18 uppercase links-unstyled font-bold">
            <li>
              <a href="#tuoteinfo">
                Tuoteinfo
              </a>
            </li>
            <li>
              <a href="#toimitus">
                Toimitus
              </a>
            </li>
          </ul>
        </div>
        <div class="grid lg:grid-cols-2 gap-12">
          <div>
            <h2>{{ product.name }}</h2>
            <div v-html="product.description" />
          </div>
          <div class="relative">
            <img
              :src="product.image"
              alt=""
              lazy="true"
              class="hidden lg:block absolute inset-0 w-full h-full max-w-full object-contain object-left"
            />
          </div>
          <div>
            <h4>Tekniset tiedot</h4>
            <div class="border rounded text-14">
              <dl class="key-value-table hl-key">
                <dt>Verhoilumateriaali</dt>
                <dd>Kangasverhoilu</dd>
                <dt>Runkomateriaali</dt>
                <dd>Mänty, Koivuvaneri, Kovalevy, Kalustelevy</dd>
                <dt>Istuinosan verhoilu</dt>
                <dd>Irtotyyny 1-puoleinen</dd>
                <dt>Selkänojan verhoilu</dt>
                <dd>Irtotyyny 2-puoleinen</dd>
                <dt>Istuinpehmeys</dt>
                <dd>Puolipehmeä</dd>
                <dt>Istuinasento</dt>
                <dd>Rento</dd>
                <dt>Tuotteen valmiusaste</dt>
                <dd>Toimitetaan jalat irrallaan</dd>
                <dt>Brändi</dt>
                <dd>
                  <a href="#">
                    Veke
                  </a>
                </dd>
                <dt>Valmistusmaa</dt>
                <dd>Liettua</dd>
                <dt>Takuu</dt>
                <dd>1 vuosi</dd>
              </dl>
            </div>
          </div>
          <div>
            <h4>Mitat</h4>
            <div class="border rounded text-14">
              <dl class="key-value-table hl-key">
                <dt>Leveys</dt>
                <dd>223 cm</dd>
                <dt>Syvyys</dt>
                <dd>92 cm</dd>
                <dt>Korkeus</dt>
                <dd>88 cm</dd>
                <dt>Istuinleveys</dt>
                <dd>208 cm</dd>
                <dt>Istuinkorkeus</dt>
                <dd>51 cm</dd>
                <dt>Istuinsyvyys</dt>
                <dd>47 cm</dd>
                <dt>Istuintyynyn paksuus</dt>
                <dd>17 cm</dd>
                <dt>Selkätyynyn paksuus</dt>
                <dd>22 cm</dd>
                <dt>Käsinojan leveys</dt>
                <dd>7 cm</dd>
                <dt>Jalan korkeus</dt>
                <dd>20 cm</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-downy py-12">
      <div class="container text-center">
        <h2 class="text-white">
          Arvostelut
        </h2>
        <div class="mx-auto max-w-screen-md p-6 shadow-md bg-white text-left mb-8">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dolores eveniet odio officiis perferendis provident sequi, ullam. Doloremque ducimus excepturi iste laboriosam nihil, obcaecati placeat quam quibusdam rerum veniam vero?</p>
        </div>
        <div class="mx-auto max-w-screen-md p-6 shadow-md bg-white text-left">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dolores eveniet odio officiis perferendis provident sequi, ullam. Doloremque ducimus excepturi iste laboriosam nihil, obcaecati placeat quam quibusdam rerum veniam vero?</p>
        </div>
      </div>
    </section>
    <section class="bg-snow py-12">
      <div class="container text-center">
        <h2 class="">
          Saatat tykätä myös
        </h2>
        <div class="lg:container">
          <div style="scroll-behavior: smooth; scroll-snap-type: both mandatory; scroll-padding-left: 64px; scroll-padding-right: 64px;" class="grid grid-flow-col lg:grid-flow-row gap-8 py-4 overflow-x-auto px-4 md:px-8 lg:px-0 justify-start lg:grid-cols-4">
            <product-tile v-for="prdct in newProducts.slice(0,4)" :key="prdct.sku" :product="prdct" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StockStatus from '@/veke3000/components/StockStatus.vue'
import Dropdown from '@bit/vekenkaluste.veke-ui.dropdown'
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import NumberField from '@bit/vekenkaluste.veke-ui.vue3.number-field'
import { useRoute } from 'vue-router'
import Breadcrumbs from '@bit/vekenkaluste.veke-ui.breadcrumbs'
import { computed, ref } from 'vue'
import { CartIcon, StarIcon, InfoIcon } from '@bit/vekenkaluste.veke-ui.icons'
import {
  newProductTiles as newProducts, getProductBySlug, products, getBread,
} from '@/veke3000/components/Products'
import ProductTile from '@/veke3000/components/ProductTile.vue'

export default {
  name: 'Product',
  components: {
    NumberField,
    Breadcrumbs,
    BaseButton,
    CartIcon,
    StarIcon,
    ProductTile,
    StockStatus,
    Dropdown,
    InfoIcon,
  },
  setup() {
    const productQty = ref(5)
    const route = useRoute()
    const product = computed(() => getProductBySlug(route.params.slug))
    const bread = getBread(product.value)
    return {
      product, products, bread, newProducts, productQty,
    }
  },
}
</script>
<style lang="scss">
#description > div {
  > p:first-child {
    @apply mt-0;
  }
  > p:last-child {
    @apply mb-0;
  }
}
</style>

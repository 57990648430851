<template>
  <div class="container-grid-wrapper" style="min-height: 420px; background: #f9fafc">
    <div class="hero">
      <img
        :src="image"
        loading="lazy"
        alt=""
        class="h-full w-full object-cover object-left absolute top-0 left-0"
      />
    </div>
    <div class="container intersect col-span-full lg:order-1 lg:col-start-2 lg:col-end-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeroContent',
  props: {
    image: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.hero {
  @apply col-span-full lg:order-2 lg:col-start-4 lg:col-end-8 relative h-full md:h-96 lg:h-full max-w-full;
  aspect-ratio: 3 / 2;
  @screen md {
    aspect-ratio: auto;
  }
}
</style>

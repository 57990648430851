<template>
  <div class="min-h-screen">
    <benefit-bar />
    <site-header />
    <site-menu />
    <section class="relative">
      <router-view v-slot="slotProps">
        <transition name="short-slide-left" mode="out-in">
          <component :is="slotProps.Component" />
        </transition>
      </router-view>
    </section>
    <site-footer :content="footerContent" />
  </div>
</template>

<script>
import BenefitBar from '@/veke3000/components/BenefitBar.vue'
import SiteHeader from '@/veke3000/components/SiteHeader.vue'
import SiteMenu from '@/veke3000/components/mobile-menu/SiteMenu.vue'
import SiteFooter from '@bit/vekenkaluste.veke-ui.vue3.footer'

const footerContent = require('@/assets/footer.json')

export default {
  name: 'Veke3000',
  components: {
    BenefitBar, SiteHeader, SiteMenu, SiteFooter,
  },
  setup() {
    return { footerContent }
  },
}
</script>

<style lang="scss" src="UI/scss/styles.scss"></style>

<template>
  <button class="w-full flex justify-between items-center h-20 border-b py-2">
    <img
      v-if="item.product"
      :src="require('@/assets/images/'+item.product)"
      loading="lazy"
      class="w-14 h-14 object-contain mr-6"
      alt=""
    />
    <span class="link-unstyled text-16 flex-1 text-left">
      {{ item.value }}
    </span>

    <chevron-right-icon v-if="item.children?.length" />
  </button>
</template>

<script>
import {
  ChevronRightIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'MenuItem',
  components: {

    ChevronRightIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      document.body.classList.remove('submenu-open')
    },
  },
}
</script>

<style lang="scss">

</style>

<template>
  <div class="lg:container">
    <div class="grid grid-flow-col gap-x-4 gap-y-1 px-4 py-4 lg:py-8 overflow-x-auto justify-start lg:px-0 lg:overflow-visible lg:grid-flow-row lg:grid-cols-3 xl:grid-cols-4">
      <router-link
        v-for="subCategory in categories"
        :key="subCategory.id"
        :to="`/veke3000/c/${subCategory.slug}`"
        class="block lg:inline lg:p-0 link-unstyled rounded bg-white hover:shadow-md"
      >
        <div class="flex-col lg:flex-row flex lg:gap-4 items-center px-4 py-2 lg:py-0 lg:px-2">
          <div class="w-14 lg:w-12 flex-none">
            <img
              v-if="subCategory.product"
              :src="require('@/assets/images/'+subCategory.product)"
              loading="lazy"
              class="w-14 h-14 lg:w-12 lg:h-12 object-contain"
              alt=""
            />
          </div>
          <div class="text-left truncate text-14 lg:text-16">
            {{ subCategory.value }}
          </div>
        </div>
      </router-link>
      <div class="w-px lg:hidden"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Subcategories',
  components: {

  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { }
  },
}
</script>

<style lang="scss" scoped>

</style>

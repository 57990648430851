<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Painikkeet</h1>
      </div>
    </div>
    <div>
      <section>
        <div class="text-center grid md:grid-cols-2 gap-8 items-center justify-center py-8 mx-auto max-w-screen-md" :class="buttonSize">
          <div>
            <base-button
              :state="buttonState"
              :disabled="isDisabled"
              :look="buttonType"
              @click="loadSuccess"
            >
              <template #default>
                <cart-icon size="1.5x" class="inline-block icon-mr" /> Lisää ostoskoriin
              </template>
              <template #error>
                Yritä uudelleen!
              </template>
              <template #success>
                <check-icon size="1.5x" class="inline-block icon-mr" /> Lisätty!
              </template>
              <template #loading>
                Lisätään<ellipsis-loading-icon size="1x" />
              </template>
            </base-button>
          </div>
          <div>
            <base-button
              :state="buttonState"
              :disabled="isDisabled"
              :look="buttonType"
              @click="loadError"
            >
              <template #default>
                <heart-icon size="1.5x" class="inline-block icon-mr" /> Lisää toivelistalle
              </template>
              <template #error>
                Yritä uudelleen!
              </template>
              <template #success>
                <check-icon size="1.5x" class="inline-block icon-mr" /> Lisätty!
              </template>
              <template #loading>
                <heart-icon size="1.5x" class="animate-bounce inline-block" />
              </template>
            </base-button>
          </div>
          <div>
            <base-button
              :state="buttonState"
              :disabled="isDisabled"
              :look="buttonType"
              transition="zoom-in"
              class="w-12 h-12 p-0"
              @click="toggleSuccess"
            >
              <template #default>
                <heart-icon size="1.5x" />
              </template>
              <template #success>
                <heart-icon fill="currentColor" size="1.5x" />
              </template>
            </base-button>
          </div>
          <div>
            <base-button
              :state="buttonState"
              :disabled="isDisabled"
              :look="buttonType"
              @click="nextTheme"
            >
              Seuraava teema
            </base-button>
          </div>
        </div>
      </section>
      <div class="">
        <div class="container py-8 grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="border p-4">
            <h3>Type</h3>
            <base-radio
              id="button-type"
              v-model="buttonType"
              name="button-type"
              :options="buttonTypes"
            />
          </div>
          <div class="border p-4">
            <h3>State</h3>
            <base-radio
              id="button-state"
              v-model="buttonState"
              name="button-state"
              :options="buttonStates"
            />
          </div>
          <div class="border p-4">
            <h3>Status</h3>
            <base-checkbox
              id="button-status"
              v-model="isDisabled"
              name="button-status"
              label="Disabled"
            />
          </div>
          <div class="border p-4">
            <h3>Font size</h3>
            <base-radio
              id="button-size"
              v-model="buttonSize"
              name="button-size"
              :options="buttonSizes"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <pre class="code">import BaseButton from '@bit/vekenkaluste.veke-ui.button'

&lt;base-button look="secondary">Click me!&lt;/base-button>

&lt;base-button :state="buttonState">
  &lt;template #default>Lisää ostoskoriin&lt;/template>
  &lt;template #loading>Lisätään...&lt;/template>
  &lt;template #success>Lisätty!&lt;/template>
&lt;/base-button></pre>
    </div>
    <div class="container mt-16">
      <h2>Painikeryhmä</h2>
      <div class="inline-grid gap-4 sm:grid-flow-col" :class="buttonSize">
        <base-button>Primary</base-button>
        <base-button look="secondary">
          Secondary
        </base-button>
        <base-button look="tertiary">
          Tertiary
        </base-button>
      </div>
      <pre class="code mt-8">&lt;div class="inline-grid gap-4 sm:grid-flow-col">

  &lt;base-button>Primary&lt;/base-button>
  &lt;base-button look="secondary">Secondary&lt;/base-button>
  &lt;base-button look="tertiary">Tertiary&lt;/base-button>

&lt;/div></pre>
    </div>
  </div>
</template>

<script>
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import BaseRadio from '@bit/vekenkaluste.veke-ui.radio'
import BaseCheckbox from '@bit/vekenkaluste.veke-ui.checkbox'
import {
  HeartIcon, CartIcon, EllipsisLoadingIcon, CheckIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'Buttons',
  components: {
    BaseButton, BaseRadio, BaseCheckbox, HeartIcon, CartIcon, EllipsisLoadingIcon, CheckIcon,
  },
  data() {
    return {
      buttonState: 'default',
      isDisabled: false,
      buttonSize: 'text-16',
      buttonType: 'primary',
      buttonTypes: [
        {
          label: 'Primary',
          value: 'primary',
        },
        {
          label: 'Secondary',
          value: 'secondary',
        },
        {
          label: 'Tertiary',
          value: 'tertiary',
        },
      ],
      buttonStates: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Loading',
          value: 'loading',
        },
        {
          label: 'Error',
          value: 'error',
        },
        {
          label: 'Success',
          value: 'success',
        },
      ],
      buttonSizes: [
        {
          label: '12px',
          value: 'text-12',
        },
        {
          label: '14px',
          value: 'text-14',
        },
        {
          label: '16px (default)',
          value: 'text-16',
        },
        {
          label: '18px',
          value: 'text-18',
        },
        {
          label: '20px',
          value: 'text-20',
        },
      ]
    }
  },
  methods: {
    loadSuccess() {
      this.buttonState = 'loading'
      setTimeout(() => {
        this.buttonState = 'success'
        setTimeout(() => {
          this.buttonState = 'default'
        }, 2000)
      }, 3000)
    },
    loadError() {
      this.buttonState = 'loading'
      setTimeout(() => {
        this.buttonState = 'error'
        setTimeout(() => {
          this.buttonState = 'default'
        }, 2000)
      }, 3000)
    },
    toggleSuccess() {
      this.buttonState = this.buttonState !== 'success' ? 'success' : 'default'
    }
  },
}
</script>

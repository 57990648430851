<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Animaatiot</h1>
      </div>
    </div>
    <div class="container mt-10">
      <section class="border grid items-center justify-center h-32 text-60">
        <transition :name="transition" :mode="transitionMode">
          <heart-icon
            :key="{ active }"
            size="1x"
            :class="{ active }"
            class="col-start-1 row-start-1"
          />
        </transition>
      </section>
      <pre class="code">&lt;transition name=&quot;{{ transition }}&quot;>&lt;/transition></pre>
      <h3>Transitions</h3>
      <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4 text-12">
        <button class="btn" @click="setTransition('none')">
          None
        </button>
        <button class="btn" @click="setTransition('fade')">
          Fade
        </button>
        <button class="btn" @click="setTransition('zoom-in')">
          Zoom In
        </button>
        <button class="btn" @click="setTransition('cube-flip-1em')">
          Cube Flip 1em
        </button>
        <button class="btn" @click="setTransition('cube-flip-2em')">
          Cube Flip 2em
        </button>
        <button class="btn" @click="setTransition('short-slide-left')">
          Short Slide Left
        </button>
        <button class="btn" @click="setTransition('short-slide-right')">
          Short Slide Right
        </button>
        <button class="btn" @click="setTransition('full-slide-left')">
          Full Slide Left
        </button>
        <button class="btn" @click="setTransition('full-slide-right')">
          Full Slide Right
        </button>
      </div>
      <h4>Mode</h4>
      <base-radio id="transitionMode" v-model="transitionMode" :options="transitionModes" />
    </div>
    <div class="container mt-12">
      <section class="border grid items-center justify-center h-32 text-60">
        <heart-icon
          size="1x"
          :class="animation"
          class="col-start-1 row-start-1"
        />
      </section>
      <pre class="code">class=&quot;{{ animation }}&quot;</pre>
      <h3>Animations</h3>
      <div class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4 text-12">
        <button
          class="btn"
          @click="setAnimation('')"
        >
          None
        </button>

        <button
          v-for="anim in animationsInConfig"
          :key="anim.name"
          class="btn"
          :disabled="!animationPreviewSet(anim.class)"
          @click="setAnimation(anim.class)"
        >
          {{ anim.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import BaseRadio from '@bit/vekenkaluste.veke-ui.radio'
import { theme } from 'UI/tailwind/tailwind.config'
import {
  HeartIcon,
} from '@bit/vekenkaluste.veke-ui.icons'

export default {
  name: 'Animations',
  components: {
    HeartIcon,
    BaseRadio,
  },
  data() {
    return {
      transition: 'none',
      transitionMode: '',
      transitionModes: [{ label: 'Default', value: '' }, { label: 'in-out', value: 'in-out' }, { label: 'out-in', value: 'out-in' }],
      animation: '',
      active: true,
      animationsInConfig: Object.keys(theme.animation).map((a) => ({ name: a, class: `animate-${a}` })),
      animations: [
        'animate-shake',
        'animate-bounce',
        'animate-rock',
        'animate-fadepulse',
      ],
    }
  },
  methods: {
    setTransition(t) {
      this.transition = t
      this.active = !this.active
    },
    setAnimation(a) {
      this.animation = ''
      setTimeout(() => {
        this.animation = a
      }, 20)
    },
    animationPreviewSet(anim) {
      return this.animations.includes(anim)
    },
  },
}
</script>
<style lang="scss" scoped>
  .active {
    fill: theme('colors.amaranth.DEFAULT');
  }
</style>

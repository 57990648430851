const campaignsArray = [
  {
    id: 1,
    slug: '/kampanjat',
    umbrella: 1,
    text: 'Sisustajan ALE',
    startDate: null,
    endDate: null,
    sortOrder: 1,
    banners: {
      hero: null,
      square: 'sale/sisustajan-ale.webp',
      horizontal: null,
      vertical: null,
    },
  },
  {
    id: 2,
    slug: '/kampanjat',
    text: 'Hellepäivien tarjoukset',
    sortOrder: 2,
    banners: {
      square: 'sale/sale-hellepaivat.jpeg',
    },
  },
  {
    id: 3,
    slug: '/kampanjat',
    text: 'Valikoima mattoja -30%',
    categories: [10],
    banners: {
      square: 'sale/sale-matot.jpeg',
    },
  },
  {
    id: 4,
    slug: '/kampanjat',
    text: 'Outlet-tuotteet -40-70%',
    banners: {
      square: 'sale/sale-outlet.jpeg',
    },
  },
  {
    id: 5,
    slug: '/kampanjat',
    text: 'Ruokailuryhmät -25%',
    banners: {
      square: 'sale/sale-ruokailuryhmat.jpeg',
    },
  },
  {
    id: 6,
    slug: '/kampanjat',
    text: 'Unia 100 -sängyt -30%',
    banners: {
      square: 'sale/sale-unia.jpeg',
    },
  },
  {
    id: 7,
    slug: '/kampanjat',
    text: 'Puutarhakalusteet -25-35%',
    banners: {
      square: 'sale/sale-puutarhakalusteet.webp',
    },
  },
  {
    id: 8,
    slug: '/kampanjat',
    text: 'Scandic Copenhagen -sohvat -30%',
    banners: {
      square: 'sale/sale-sohvat.webp',
    },
  },
]

const campaigns = campaignsArray.sort((a, b) => a.sortOrder - b.sortOrder)

const umbrellaCampaigns = campaigns.filter((cat) => cat.umbrella)

export {
  campaigns,
  umbrellaCampaigns,
}

<template>
  <div>
    <div class="bg-gunmetal py-4">
      <navigation :items="layoutNavigation" :root="'layout'" />
    </div>
    <section>
      <component :is="layoutComponent" />
    </section>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import OneColumn from '@/components/layouts/OneColumn.vue'
import SidebarLeft from '@/components/layouts/SidebarLeft.vue'
import Action from '@/components/layouts/Action.vue'
import Blog from '@/components/layouts/Blog.vue'

export default {
  name: 'Layout',
  components: {
    Navigation,
    OneColumn,
    SidebarLeft,
    Action,
    Blog,
  },
  props: {
    layout: {
      type: String,
      required: true,
      default: 'one-column',
    },
  },
  data() {
    return {
      layoutNavigation: [
        {
          name: 'Yksi sarake',
          path: 'one-column',
        },
        {
          name: 'Sivupalkilla',
          path: 'sidebar-left',
        },
        {
          name: 'Kapea',
          path: 'action',
        },
        {
          name: 'Blogi',
          path: 'blog',
        },
      ],
    }
  },
  computed: {
    layoutComponent() {
      return this.layout || 'one-column'
    },
  },
}
</script>

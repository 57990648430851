const statuses = [
  {
    label: 'Varastossa yli 10 kpl',
    code: 'in_stock',
    icon: 'check-icon',
    minQty: 11,
  },
  {
    label: 'Varastossa # kpl',
    code: 'in_stock',
    icon: 'check-icon',
    minQty: 1,
  },
  {
    label: 'Tilaustuote',
    code: 'backorder',
    icon: 'ship-icon',
    backorder: true,
    minQty: 0,
  },
  {
    label: 'Loppu varastosta',
    code: 'out_of_stock',
    icon: 'x-icon',
    backorder: false,
    minQty: 0,
  },
]

const getStockStatus = (qty = 0, backorder = false) => statuses.sort((a, b) => b.minQty - a.minQty)
  .map((sts) => ({ ...sts, label: sts.label.replace('#', qty) })).find((s) => s.minQty <= qty && (qty || (s.backorder || false) === backorder))

/* TESTS */
/*
console.log('Loppu varastosta', '=>', getStockStatus(0, false).label)
console.log('Tilaustuote', '=>', getStockStatus(0, true).label)
console.log('Varastossa 1 kpl', '=>', getStockStatus(1, true).label)
console.log('Varastossa 5 kpl', '=>', getStockStatus(5, true).label)
console.log('Varastossa 5 kpl', '=>', getStockStatus(5, false).label)
console.log('Varastossa 10 kpl', '=>', getStockStatus(10, false).label)
console.log('Varastossa yli 10 kpl', '=>', getStockStatus(13, false).label)
console.log('Varastossa yli 10 kpl', '=>', getStockStatus(13, true).label)
console.log('Varastossa yli 10 kpl', '=>', getStockStatus(430, true).label)
*/

export {
  getStockStatus,
}

<template>
  <div class="flex justify-between items-center" :class="color">
    <span itemscope="" class="uppercase font-bold text-12 tracking-wide">
      <link itemprop="availability" href="http://schema.org/InStock" />
      {{ stock.label }}
    </span>
    <span class="w-5 h-5 flex items-center justify-center rounded-full bg-current inline-block">
      <component :is="stock.icon" size="1x" class="text-white" />
    </span>
  </div>
</template>

<script>
import { getStockStatus } from '@/veke3000/components/Stock'
import { ShipIcon, XIcon, CheckIcon } from '@bit/vekenkaluste.veke-ui.icons'
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'StockStatus',
  components: { ShipIcon, CheckIcon, XIcon },
  props: {
    qty: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    const stock = computed(() => getStockStatus(props.qty))
    const color = computed(() => {
      const map = {
        in_stock: 'text-jade',
        out_of_stock: 'text-amaranth',
        backorder: 'text-turquoise',
      }
      return map[stock.value.code] || 'text-gunmetal'
    })
    return {
      stock, color,
    }
  },
})
</script>
<style lang="scss" scoped>

</style>

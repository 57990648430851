<template>
  <div class="container">
    <heading-link to="/kampanjat" button-text="Näytä lisää">
      <h2>Tyylikkäitä uutuuksia</h2>
    </heading-link>
  </div>
  <div class="lg:container">
    <div style="scroll-behavior: smooth; scroll-snap-type: both mandatory; scroll-padding-left: 64px; scroll-padding-right: 64px;" class="grid grid-flow-col lg:grid-flow-row gap-8 py-4 overflow-x-auto px-4 md:px-8 lg:px-0 lg:gap-12 justify-start lg:grid-cols-4">
      <product-tile v-for="product in newProducts" :key="product.sku" :product="product" />
    </div>
  </div>
</template>

<script>
import HeadingLink from '@/veke3000/components/HeadingLink.vue'
import ProductTile from '@/veke3000/components/ProductTile.vue'
import { newProductTiles as newProducts } from '@/veke3000/components/Products'

export default {
  name: 'NewProducts',
  components: {
    HeadingLink,
    ProductTile,
  },
  setup() {
    return { newProducts }
  },
}
</script>

<template>
  <ul class="dropmenu links-unstyled theme-default">
    <div class="beta break-words hyphens-auto">
      {{ menu.value }}
    </div>
    <img :src="require('@/assets/images/'+categoryImage)" class="w-full object-cover h-36 mb-2" />
    <li v-for="beta in menu.children" :key="beta.id" class="dropmenu-col">
      <router-link :to="'/veke3000/c/'+beta.slug" class="block font-bold">
        {{ beta.value }}
      </router-link>

      <ul v-if="beta.children.length" class="list-reset text-12 mt-3">
        <li v-for="gamma in beta.children" :key="gamma.id" class="my-2 whitespace-nowrap truncate">
          <router-link :to="'/veke3000/c/'+gamma.slug">
            {{ gamma.value }}
          </router-link>
        </li>
      </ul>
    </li>
    <base-button look="secondary" class="w-full" :to="'/veke3000/c/'+menu.slug">
      Koko kategoria
    </base-button>
  </ul>
</template>

<script>
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import {
  defineComponent,
  computed,
} from 'vue'

export default defineComponent({
  name: 'MegaSubMenu',
  components: {
    BaseButton,
  },
  props: {
    openedId: {
      type: String,
      required: false,
      default: '',
    },
    menu: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const categoryImage = computed(() => props.menu?.image || 'category_huonekalut.webp')
    return { categoryImage }
  },
})
</script>

<style lang="scss">

.megamenu .menu-open > .dropmenu {
  @apply opacity-100 inset-x-0 translate-y-0 pointer-events-auto z-50;
}

.megamenu.has-open-menu .menu-open > .dropmenu {
  transition: none;
}
.megamenu.has-open-menu li > .dropmenu {
  @apply left-0;
}

.dropmenu {
  @apply opacity-0 pointer-events-none absolute transform -translate-y-4 -left-full w-full top-44 shadow-lg rounded bg-white p-6 pt-2 m-0 z-40 list-none text-14;
  transition: opacity 300ms, transform 300ms;
  column-width: 140px;
  column-gap: 24px;
  // column-rule: 1px solid #c8c8c8;
  column-count: 5;

  @media (min-width: 1080px) {
    column-count: 6;
  }
}
.dropmenu-col {
  page-break-inside: avoid;
  break-inside: avoid;
  @apply bg-whitesmoke p-4;
}

</style>

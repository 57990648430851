<template>
  <filter-dropdown :label="filter.name">
    <template #selection>
      {{ preview }}
    </template>
    <checkbox-group
      :id="filter.id+'_'+uid"
      v-model="selectedValues"
      :name="filter.id"
      :options="filter.options"
      class="gap-px"
    />
  </filter-dropdown>
</template>

<script>
import {
  getCurrentInstance,
  computed,
  defineComponent, ref,
} from 'vue'
import FilterDropdown from '@/veke3000/components/filters/FilterDropdown.vue'
import CheckboxGroup from '@bit/vekenkaluste.veke-ui.checkbox-group'

export default defineComponent({
  name: 'CheckboxFilter',
  components: {
    FilterDropdown,
    CheckboxGroup,
  },
  inheritAttrs: false,
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { uid } = getCurrentInstance() // TODO: Do not use uid
    const selectedValues = ref([])
    const selections = computed(() => selectedValues.value.map((o) => props.filter.options.find((opt) => opt.value === o)))
    const preview = computed(() => selections.value.map((o) => o.label).join(', '))
    return {
      selectedValues, preview, selections, uid,
    }
  },
})
</script>

<template>
  <div>
    <div class="bg-flax">
      <div class="container flex items-center justify-left h-32">
        <h1>Puutarhakalustetrendit 2021</h1>
      </div>
    </div>
    <div class="bg-flax">
      <nav id="breadcrumbs" class="container py-4">
        Blogi / Puutarhakalustetrendit 2021
      </nav>
    </div>
    <div class="container-grid my-12">
      <div class="content md:text-18 lg:text-20 xl:text-20">
        <h2>Haaveillaanko hetki kesästä?</h2>
        <p>Kuvittele lämmin kesäpäivä ja lempeä kesätuuli kasvoillasi. Ei kiire mihinkään. Mitä kaipaat ympärillesi? Onko se leppoisaa kesäkeidasta, mahdollisuutta ulkoruokailulle, kesäkeittiötä tai kenties hyötypuutarhaa?</p>
        <p>Maailmanlaajuinen pandemia on muuttanut elämäämme paljon, kenties pysyvästikin. Liikumme enemmän luonnossa, myös kotiin ja asumisen viihtyvyyteen panostetaan nyt enemmän kuin koskaan aiemmin. Kesä alkaa olla nurkan takana ja voikin olla, että tämän vuoden kesälomasuunnitelmat suunnitellaan oman takapihan keitaalle. Tee siitä mahdollisimman kutsuva, jossa viihdytte yhdessä koko perheen kanssa.</p>
        <p>Postauksessa pureudutaan vuoden 2021 puutarhakaluste ja ulkotilojen trendeihin. Poimi ideat omaa pihaa, patiota, parveketta tai terassia varten.</p>
        <h2>Puutarhakalusteiden / ulkotilojen trendit 2021 x 12</h2>
        <ul>
          <li>Maanläheisyys</li>
          <li>Luonnonmateriaalit kuten puu, rottinki ja bambu</li>
          <li>Viherkeidas / runsas kasvien käyttö</li>
          <li>Metalliset kalusteet</li>
          <li>Lounge- tyyppiset kalusteet</li>
          <li>Tuplakeinut</li>
          <li>Selkeät linjat</li>
          <li>Pyöreät muodot</li>
          <li>Kirkkaat värit</li>
          <li>Mukavuuden korostaminen</li>
          <li>Kesäkeittiöt</li>
          <li>Ulkovalaistukseen panostaminen sekä elävä tuli</li>
        </ul>
        <hr>
        <figure>
          <img src="https://vekedotblog.files.wordpress.com/2021/02/82040942_98.jpg" alt="Kuva maanläheisesti kalustetusta terassista" />
          <figcaption>Kuva: Bloomingville</figcaption>
        </figure>
        <figure>
          <img src="https://vekedotblog.files.wordpress.com/2021/02/82040942_99.jpg" alt="Kuva pöydästä ja tuolista terassilla" />
          <figcaption>Kuva: Bloomingville</figcaption>
        </figure>
        <h2>Maanläheisyyttä ja luonnonmateriaaleja</h2>
        <p>Maanläheisyys niin materiaalien kuin sävyjen muodossa on trendikästä myös ulkotiloissa. Luonnonmateriaalit, kuten puu, rottinki sekä bambu sulautuvat saumattomasti ympäröivään luontoon. Kun lisäät kokonaisuuteen vielä ruskean ja beigen eri sävyjä tekstiileillä ja muilla yksityiskohdilla, on maanläheinen kokonaisuus taattu. Viherkeitaan tunnelmaa saat luotua runsaiden kasvien sekä heinien avulla.</p>
        <p>Aitojen materiaaleja, erityisesti rottinkia ja bambua ei saa jättää sään armoille. Parhain paikka näille kalusteille on katettu tai lasitettu tila.</p>
        <p>Myös säänkestävissä materiaaleissa käytetään paljon aitojen materiaalien jäljittelyjä. Erilaiset muovimateriaalit, kuten polyrottinki ovat huomattavasti kestävämpi ja huoltovapaampi vaihtoehto ulkokalusteissa.</p>
        <p>Metalliset kalusteet ovat ovat trendikkäiden kalusteiden kärjessä tänä vuonna!</p>
        <figure>
          <img src="https://vekedotblog.files.wordpress.com/2021/02/aava_sohvaryhma_1.jpg" alt="Kuva terassille tarkoitetusta sohvaryhmästä" />
          <figcaption>Aava sohvaryhmä on valmistettu kestävästä alumiinista sekä aintwoodista.</figcaption>
        </figure>
        <figure>
          <img src="https://vekedotblog.files.wordpress.com/2021/02/82040931_15.jpg" alt="Kuva terassille tarkoitetusta sohvaryhmästä" />
          <figcaption>Kuva: Bloomingville</figcaption>
          <p>Jos kalustus on boho-henkinen, saat kasvien runsastamana omalle pihallesi ripauksen tropiikin tuntua.</p>
          <hr>
          <p>Trendikäs tapa sijoittaa kasvit ja heinät on rehevät rykelmät eri kokoisiin ruukkuihin. Jos kaipaat huolettomampaa ilmettä, voit käyttää erilaisia ruukkuja esillepanoissa. Kasveja voi myös sijoittaa seinää pitkin kiertämään tai katosta roikkumaan. Mitä enemmän ja monipuolisemmim kasveja sijoitat kokonaisuuteen, sitä vehreämmän keitaan saat.</p>
          <p>Rönsyilevät heinät ovat nousseet suureen suosioon kasvien rinnalle ja helppohoitoisuutta arvostavalle onkin tarjolla upeita kestoversioita. Lasitettuun tilaan voikin sijoittaa niin tekoheiniä kuin tekokasveja. Sään armoille emme suosittele tuotteita jättämään.</p>
        </figure>
      </div>
    </div>
  </div>
</template>

<template>
  <h2>Yhteystiedot</h2>
  <div class="grid gap-4 md:grid-cols-3">
    <base-input
      v-model="form.email"
      label="Sähköpostiosoite"
      type="email"
      :validator="v$.email"
      required
      name="feedback-email"
      autocomplete="email"
      wrapper-class="md:col-span-3"
    />
    <base-input
      v-model="form.name"
      :validator="v$.name"
      label="Nimi"
      required
      name="feedback-name"
      autocomplete="name"
      wrapper-class="md:col-span-3"
    />
    <base-input
      v-model="form.street"
      :validator="v$.street"
      required
      label="Katuosoite"
      name="feedback-street"
      autocomplete="street-address"
      wrapper-class="md:col-span-3"
    />
    <base-input
      v-model="form.postcode"
      :validator="v$.postcode"
      required
      label="Postinumero"
      name="feedback-postcode"
      wrapper-class="md:col-span-1"
    />
    <base-input
      v-model="form.city"
      :validator="v$.city"
      label="Kaupunki"
      name="feedback-city"
      required
      wrapper-class="md:col-span-2"
    />
    <base-select
      v-model="form.country"
      :validator="v$.country"
      required
      name="country"
      label="Maa"
      placeholder="Valitse maa"
      wrapper-class="md:col-span-3"
    >
      <option value="fi">
        Suomi
      </option>
      <option value="swe">
        Ruotsi
      </option>
      <option value="nor">
        Norja
      </option>
    </base-select>
    <base-input
      v-model="form.phone"
      label="Puhelinnumero"
      :validator="v$.phone"
      required
      name="feedback-phone"
      autocomplete="phone"
      wrapper-class="md:col-span-3"
    />
    <base-checkbox
      v-model="form.marketing"
      :validator="v$.marketing"
      label="Veke saa lähettää minulle sähköpostia"
      name="feedback-marketing"
      wrapper-class="md:col-span-3 my-4"
    />
    <div class="md:col-span-3">
      <base-button class="w-full md:w-auto" @click="sendForm">
        Tallenna yhteystiedot
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@bit/vekenkaluste.veke-ui.button'
import BaseCheckbox from '@bit/vekenkaluste.veke-ui.checkbox'
import BaseSelect from '@bit/vekenkaluste.veke-ui.select'
import BaseInput from '@bit/vekenkaluste.veke-ui.input'
import useVuelidate from '@vuelidate/core'
import { reactive } from 'vue'
import {
  helpers, required, numeric, email,
} from '@vuelidate/validators'

export default {
  name: 'FeedbackForm',
  components: {
    BaseButton,
    BaseCheckbox,
    BaseSelect,
    BaseInput,
  },
  setup() {
    const form = reactive({ country: 'fi' })
    const validations = {
      required: helpers.withMessage('Vaadittu kenttä', required),
      numeric: helpers.withMessage('Käytä vain numeroita', numeric),
      email: helpers.withMessage('Tarkista sähköpostiosoitteen muoto', email),
    }
    const rules = {
      email: {
        email: validations.email,
        required: validations.required,
      },
      name: {
        required: validations.required,
      },
      street: {
        required: validations.required,
      },
      postcode: {
        required: validations.required,
        numeric: validations.numeric,
      },
      city: {
        required: validations.required,
      },
      country: {
        required: validations.required,
      },
      phone: {
        required: validations.required,
        numeric: validations.numeric,
      },
      marketing: {
        required: validations.required,
      },
    }
    const v$ = useVuelidate(rules, form)
    const sendForm = async () => {
      await v$.value.$validate()
    }
    return {
      sendForm, v$, form,
    }
  },
}
</script>

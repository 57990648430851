<template>
  <div>
    <div class="bg-flax">
      <div class="container py-8 lg:py-12">
        <h1>Typografia</h1>
      </div>
    </div>
    <section class="container my-12">
      <div class="grid sm:grid-cols-6 gap-y-12 gap-x-4">
        <template v-for="i in 6" :key="i">
          <div>
            <div class="gamma m-0">
              H{{ i }}
            </div>
            <code class="mt-2">
              .{{ titleNames[i-1] }}
            </code>
          </div>
          <div class="col-span-5">
            <component
              :is="'h' + i"
              class="m-0"
            >
              Nukkuminen ja hyvä sänky osana hyvinvointia
            </component>
          </div>
        </template>
        <!-- -->
        <div>
          <div class="gamma m-0">
            Ingressi
          </div>
          <code class="mt-2">
            .lead
          </code>
        </div>
        <div class="col-span-5">
          <p class="lead m-0">
            Sisustaja voi katsoa maton valintaa monelta kantilta.
            Yksinkertaisimmillaan valinnan voi karsia kolmeen kysymykseen; mikä
            koko, mikä materiaali ja mikä väri? Tässä noin kolme vinkkiä
            tarkentavien jatkokysymyksien asetteluun.
          </p>
        </div>
        <!-- -->
        <div class="gamma m-0">
          Leipäteksti
        </div>
        <div class="col-span-5">
          <p class="mt-0">
            <strong>Hanki oikean kokoinen matto</strong>. Mattoa hankkiessaan moni
            tyytyy vaikiomittoihin 140 x 200 cm, 160 x 230 cm ja 200 x 300 cm tai
            rullamaton leveyteen 80 cm. Varsinkin suuria tiloja sisustettaessa
            olisi kuitenkin hyödyllistä tilan ilmeen ja toiminnallisuuden kannalta
            hankkia juuri huoneeseen sopivan kokoinen matto mittojen mukaan.
          </p>
          <p>
            Mittatilausmatto on neliöhinnaltaan jonkin verran vakiokokoista mattoa
            arvokkaampi, mutta usein satsaaminen pitkäikäiseen tuotteeseen ja
            jokapäiväiseen asumismukavuuteen kannattaa. Kauttamme voit tilata
            kotimaiset VM Carpetin matot mittojen mukaan. Tutustu valikoimaan ja
            tilaa juuri sinun kotiisi sopiva matto!
          </p>

          <p class="text-10">
            <strong>Hanki oikean kokoinen matto</strong>. Mattoa hankkiessaan moni
            tyytyy vaikiomittoihin 140 x 200 cm, 160 x 230 cm ja 200 x 300 cm tai
            rullamaton leveyteen 80 cm. Varsinkin suuria tiloja sisustettaessa
            olisi kuitenkin hyödyllistä tilan ilmeen ja toiminnallisuuden kannalta
            hankkia juuri huoneeseen sopivan kokoinen matto mittojen mukaan.
          </p>
          <p class="text-11">
            <strong>Hanki oikean kokoinen matto</strong>. Mattoa hankkiessaan moni
            tyytyy vaikiomittoihin 140 x 200 cm, 160 x 230 cm ja 200 x 300 cm tai
            rullamaton leveyteen 80 cm. Varsinkin suuria tiloja sisustettaessa
            olisi kuitenkin hyödyllistä tilan ilmeen ja toiminnallisuuden kannalta
            hankkia juuri huoneeseen sopivan kokoinen matto mittojen mukaan.
          </p>          <p class="text-12">
            <strong>Hanki oikean kokoinen matto</strong>. Mattoa hankkiessaan moni
            tyytyy vaikiomittoihin 140 x 200 cm, 160 x 230 cm ja 200 x 300 cm tai
            rullamaton leveyteen 80 cm. Varsinkin suuria tiloja sisustettaessa
            olisi kuitenkin hyödyllistä tilan ilmeen ja toiminnallisuuden kannalta
            hankkia juuri huoneeseen sopivan kokoinen matto mittojen mukaan.
          </p><p class="text-14">
            <strong>Hanki oikean kokoinen matto</strong>. Mattoa hankkiessaan moni
            tyytyy vaikiomittoihin 140 x 200 cm, 160 x 230 cm ja 200 x 300 cm tai
            rullamaton leveyteen 80 cm. Varsinkin suuria tiloja sisustettaessa
            olisi kuitenkin hyödyllistä tilan ilmeen ja toiminnallisuuden kannalta
            hankkia juuri huoneeseen sopivan kokoinen matto mittojen mukaan.
          </p>
        </div>
        <!-- -->
        <div class="gamma m-0">
          Linkki
        </div>
        <div class="col-span-5">
          <p class="mt-0">
            <a href="#">
              Tässä linkki!
            </a>
          </p>
          <p>
            <a href="#" class="link-unstyled">
              Tässä tyylittelemätön linkki!
            </a> <code>.link-unstyled</code>
          </p>
          <div class="grid grid-cols-4">
            <div class="bg-rain p-4">
              Tässä <a href="#">linkki</a> värillisellä taustalla
            </div>
            <div class="bg-rose p-4">
              <a href="#">linkki</a>
            </div>
            <div class="bg-flax p-4">
              <a href="#">linkki</a>
            </div>
            <div class="bg-forest text-white p-4">
              <a href="#">linkki</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Typography',
  data() {
    return {
      titleNames: [
        'alpha',
        'beta',
        'gamma',
        'delta',
        'epsilon',
        'zeta',
      ],
    }
  },
}
</script>

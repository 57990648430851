<template>
  <template v-for="filter in filters" :key="filter.id">
    <component :is="filter.component" :filter="filter" />
  </template>
</template>

<script>
import {
  defineComponent,
} from 'vue'
import CheckboxFilter from '@/veke3000/components/filters/types/CheckboxFilter.vue'
import RangeFilter from '@/veke3000/components/filters/types/RangeFilter.vue'
import PriceFilter from '@/veke3000/components/filters/types/PriceFilter.vue'
import SizeFilter from '@/veke3000/components/filters/types/SizeFilter.vue'
import ColorFilter from '@/veke3000/components/filters/types/ColorFilter.vue'

const filters = require('@/assets/filters.json')

export default defineComponent({
  name: 'Filters',
  components: {
    CheckboxFilter,
    RangeFilter,
    ColorFilter,
    SizeFilter,
    PriceFilter,
  },
  setup() {
    return {
      filters,
    }
  },
})
</script>
